import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import {
  AiFillCheckCircle,
  AiOutlineCalendar,
  AiOutlineLink,
  AiOutlinePlus,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdLibraryAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RecipeVm } from "../../apiClient/clients";
import {
  useAddRecipeToRecipeCollection,
  useAddRecipeToShoppingList,
  useAuthentication,
  useCloneRecipe,
  useDeleteRecipe,
  useGetShoppingLists,
} from "../../hooks";
import useGetRecipeCollectionsWithoutRecipe from "../../hooks/recipeCollections/useGetRecipeCollectionsWithoutRecipe";
import useGetUser from "../../hooks/users/useGetUser";
import { brand500Color } from "../../pages/_app";
import {
  handleApiLimitError,
  ProblemDetails,
  sortedRecipeCollectionItems,
  sortShoppingListsByOwned,
  writeToClipboard,
} from "../../utils";
import MenuTextItem from "../Cards/MenuTextItem";
import { LoadingSpinner } from "../LoadingSpinner";
import { UpgradeModal } from "../Modals";
import CreateShoppingListModal from "../Modals/CreateShoppingListModal";
import DeleteAlertDialog from "../Modals/DeleteAlertDialog";
import MealPlannerSelectModal from "../Modals/MealPlannerSelectModal";

type Props = {
  recipe: RecipeVm;
  isAccessedFromCollectionPage?: boolean;
  onDelete?: () => void;
};

const RecipeMenu = ({
  recipe,
  isAccessedFromCollectionPage,
  onDelete,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const [apiLimitError, setApiLimitError] = useState<string | undefined>();

  const { cloneRecipeMutate, isLoading: isCloneRecipeLoading } = useCloneRecipe(
    {}
  );

  const { isLoading: loading, isAuthenticated } = useAuthentication();

  //TODO: Can pass this in from the pages to stop it loading on every onOpen.
  const { isFetching: isGetUserLoading, data: getUserData } =
    useGetUser(shouldFetchData);

  const {
    isOpen: isCreateShoppingListModalOpen,
    onOpen: onOpenShoppingListModal,
    onClose: onCloseShoppingListModal,
  } = useDisclosure();

  const {
    isOpen: isMealPlannerModalOpen,
    onOpen: onMealPlannerModalOpen,
    onClose: onMealPlannerModalClose,
  } = useDisclosure();

  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleAddApiLimitError = (err: ProblemDetails) => {
    handleApiLimitError(err, setApiLimitError, onOpenUpgradeModal);
  };

  const { addRecipeToShoppingListMutate } = useAddRecipeToShoppingList({
    onError: handleAddApiLimitError,
  });

  const { addRecipeToRecipeCollectionMutate } = useAddRecipeToRecipeCollection({
    onError: handleAddApiLimitError,
  });

  const isCreatedByCurrentUser = recipe.createdByUserId == getUserData?.id;

  const showDeleteButton = isAuthenticated && isCreatedByCurrentUser;

  const {
    data: recipeCollectionData,
    isLoading: isGetRecipeCollectionsLoading,
    isFetching: isGetRecipeCollectionsFetching,
  } = useGetRecipeCollectionsWithoutRecipe(recipe.id, shouldFetchData);

  const isDataLoading =
    isGetRecipeCollectionsLoading || isGetRecipeCollectionsFetching;
  //TODO: is this used anywhere?
  const { deleteRecipeMutate, isLoading: isDeleteLoading } = useDeleteRecipe(
    {}
  );

  const handleShoppingListModalSubmit = () => console.log("submit");
  const handleCancelDelete = () => console.log("cancel");

  const handleSubmitDelete = () => {
    onDelete && onDelete();
    deleteRecipeMutate(recipe.id);
  };

  const {
    data: shoppingListData,
    isLoading: isGetShoppingListLoading,
    isFetching,
  } = useGetShoppingLists(undefined, shouldFetchData);

  // useEffect(() => {
  //   if (shouldFetchData) {
  //     setShouldFetchData(false);
  //   }
  // }, [shouldFetchData]);

  const sortedRecipeCollections = sortedRecipeCollectionItems(
    recipe,
    recipeCollectionData
  );

  return (
    <>
      <CreateShoppingListModal
        recipeId={recipe.id}
        isOpen={isCreateShoppingListModalOpen}
        onOpen={onOpenShoppingListModal}
        onClose={onCloseShoppingListModal}
        onCancel={onCloseShoppingListModal}
        onSubmit={handleShoppingListModalSubmit}
      />
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />
      <MealPlannerSelectModal
        isOpen={/*true*/ isMealPlannerModalOpen}
        onOpen={onMealPlannerModalOpen}
        onClose={onMealPlannerModalClose}
        recipe={recipe}
        hideSelectRecipe={true}
      />
      <DeleteAlertDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
      />
      <Menu
        onClick={(e) => {
          e.stopPropagation();
        }}
        unmountOnClose={true}
        initialMounted={false}
        menuStyle={{ maxWidth: "250px" }}
        menuButton={
          <MenuButton
            style={{ height: "25px", width: "50px" }}
            onClick={(e) => {
              e.stopPropagation();
              //We dont want to load the APi data from the collcection page, since we dont have the shopping list etc on that page.
              //Also only fetch if they're authenticated.
              if (!isAccessedFromCollectionPage && isAuthenticated) {
                setShouldFetchData(true);
              }
            }}
          >
            <BsThreeDotsVertical style={{ marginLeft: "25px" }} />
          </MenuButton>
        }
      >
        <MenuItem
          onClick={() => {
            writeToClipboard(`recipes/${recipe.id}`);
          }}
        >
          <MenuTextItem
            icon={<AiOutlineLink size="20px" />}
            text="Copy Share Link"
          />
        </MenuItem>
        {isAuthenticated && (
          <>
            <MenuDivider />
            {isCreatedByCurrentUser ? (
              <>
                <SubMenu label="Add to recipe collection">
                  <MenuHeader>Recipe Collections</MenuHeader>

                  {isDataLoading || isGetUserLoading ? (
                    <LoadingSpinner size="md" />
                  ) : (
                    <Flex
                      flexDirection={"column"}
                      minHeight="100px"
                      maxHeight="250px"
                      maxW={"250px"}
                      overflowY={"auto"}
                    >
                      {sortedRecipeCollections?.length ? (
                        sortedRecipeCollections?.map((r) => {
                          const isAlreadyAdded = !!r.recipes?.find(
                            (x) => x.recipeId === recipe.id
                          );
                          return (
                            <MenuItem
                              disabled={isAlreadyAdded}
                              key={r.id}
                              onClick={(e) => {
                                addRecipeToRecipeCollectionMutate({
                                  collectionId: r.id,
                                  recipeId: recipe.id,
                                });
                              }}
                            >
                              <Flex alignItems={"center"} gap="5px">
                                {isAlreadyAdded && (
                                  <AiFillCheckCircle color="green" />
                                )}
                                <Text noOfLines={1}>{r.name}</Text>
                              </Flex>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <>
                          <Text
                            padding={"15px"}
                          >{`You either have no collections or the recipe is already added to all collections.`}</Text>
                          <Text padding="15px">
                            Create a new collection to add it.
                          </Text>
                        </>
                      )}
                    </Flex>
                  )}
                </SubMenu>

                <SubMenu label="Add to shopping list">
                  <MenuHeader>Shopping Lists</MenuHeader>

                  {isGetShoppingListLoading ||
                  isFetching ||
                  isGetUserLoading ? (
                    <LoadingSpinner size="md" />
                  ) : (
                    <Flex
                      flexDirection={"column"}
                      minHeight="100px"
                      maxHeight="250px"
                      maxW={"250px"}
                      overflowY={"auto"}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation = true;
                          onOpenShoppingListModal();
                        }}
                      >
                        <MenuTextItem
                          icon={<AiOutlinePlus color="#8952e0" />}
                          text="Create a shopping list"
                        />
                      </MenuItem>

                      {sortShoppingListsByOwned(shoppingListData, recipe)?.map(
                        (r) => {
                          const isAlreadyAdded = !!r.recipes?.find(
                            (x) => x.id === recipe.id
                          );
                          return (
                            <MenuItem
                              disabled={isAlreadyAdded}
                              key={r.id}
                              onClick={(e) => {
                                addRecipeToShoppingListMutate({
                                  shoppingListId: r.id,
                                  recipeId: recipe.id,
                                });
                              }}
                            >
                              <Flex alignItems={"center"} gap="5px">
                                {isAlreadyAdded && (
                                  <AiFillCheckCircle color="green" />
                                )}
                                <Text noOfLines={1}>{r.name}</Text>
                              </Flex>
                            </MenuItem>
                          );
                        }
                      )}
                    </Flex>
                  )}
                </SubMenu>

                <MenuItem
                  onClick={() => {
                    onMealPlannerModalOpen();
                  }}
                >
                  <MenuTextItem
                    icon={<AiOutlineCalendar color={brand500Color} />}
                    text="Add to meal plan"
                  />
                </MenuItem>
              </>
            ) : (
              <MenuItem
                disabled={isCloneRecipeLoading}
                onClick={() => cloneRecipeMutate(recipe.id)}
              >
                <MenuTextItem
                  icon={
                    <MdLibraryAdd
                      size="20px"
                      fontWeight={"bold"}
                      color={brand500Color}
                    />
                  }
                  text="Save to my recipes"
                />
              </MenuItem>
            )}

            {showDeleteButton && (
              <MenuItem
                onClick={() => {
                  onOpen();
                }}
              >
                <MenuTextItem
                  icon={<RiDeleteBin5Line color="red" />}
                  text="Delete"
                />
              </MenuItem>
            )}
            <MenuDivider />
          </>
        )}
      </Menu>
    </>
  );
};

export default RecipeMenu;
