import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import "@szhsin/react-menu/dist/index.css";

import { useRouter } from "next/router";
import {
  RecipeCollectionWithRecipesVm,
  RecipeVm,
} from "../../apiClient/clients";

import LazyLoad from "react-lazy-load";
import { Shimmer } from "react-shimmer";
import getImageFromRecipe from "../../utils/getImageFromRecipe";
import { RecipeMenu } from "../Menus";
import RecipeCollectionRecipeCardMenu from "../Menus/RecipeCollectionRecipeCardMenu";

type Props = {
  recipe: RecipeVm;
  onClickUrl?: string;
  isAccessedFromCollectionPage?: boolean;
  recipeCollection?: RecipeCollectionWithRecipesVm;
};

const RecipeCard = ({
  recipe,
  onClickUrl,
  isAccessedFromCollectionPage,
  recipeCollection,
}: Props) => {
  const hasUploadedImage = !!recipe.recipeImages?.[0]?.url;

  const router = useRouter();
  return (
    <div id={recipe.id} style={{ height: "350px" }}>
      <Card
        h="350px" //TODO:If grid breaks -  Added a max height.
        borderRadius={"lg"}
        variant="outline"
        bg="white"
        onClick={() =>
          router.push(onClickUrl ? onClickUrl : `recipes/${recipe.id}`)
        }
        maxW="xs"
        size="sm"
        shadow={"md"}
        //   bg={useColorModeValue("gray.50", "gray.900")}
        // bg="unset"
        border={"3px solid transparent"}
        // _hover={{
        //   cursor: "pointer",
        //leaving these commented out as they kind of jank up scrolling on mobile (on ios app)
        //   transform: "translateY(-5px) scale(1.005) translateZ(0)",
        //   // border: "3px solid",
        //   //TODO: Get Theme here.
        //   // borderColor: "purple",
        // }}
      >
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Box minH={"70px"}>
                <Heading wordBreak={"break-word"} noOfLines={3} size="sm">
                  {recipe.name}
                </Heading>
                {recipe.isGloballyVisible && !isAccessedFromCollectionPage && (
                  <Badge marginBottom={"1"} colorScheme="green">
                    Featured
                  </Badge>
                )}
              </Box>
            </Flex>
            {isAccessedFromCollectionPage ? (
              <RecipeCollectionRecipeCardMenu
                recipe={recipe}
                isAccessedFromCollectionPage={isAccessedFromCollectionPage}
                recipeCollection={recipeCollection}
              />
            ) : (
              <RecipeMenu
                recipe={recipe}
                isAccessedFromCollectionPage={isAccessedFromCollectionPage}
              />
            )}
          </Flex>
          <Divider />
        </CardHeader>

        <Stack divider={<StackDivider />} spacing="4">
          <CardBody>
            <Flex justify={"center"}>
              <LazyLoad>
                <Image
                  // loading="lazy"
                  h={hasUploadedImage ? "200px" : "200px"}
                  w={hasUploadedImage ? "100%" : "200px"}
                  src={
                    getImageFromRecipe({
                      sizeOption: "500_500",
                      recipeImage: recipe.recipeImages?.[0],
                    })
                    // recipe.recipeImages?.[0]?.cdnUrl
                    //   ? recipe.recipeImages?.[0].cdnUrl + imageSizeQueryParams()
                    //   : "/MainLogo.svg"
                  }
                  fallback={
                    <Flex>
                      <Shimmer height={200} width={150} />
                    </Flex>
                  }
                  alt="Recipe Image"
                  // layout="fill"
                  objectFit={"cover"}
                  // objectFit="contain"
                  borderRadius={"lg"}
                  justifyContent="center"
                />
              </LazyLoad>
            </Flex>
          </CardBody>
        </Stack>
      </Card>
    </div>
  );
};

export default RecipeCard;
