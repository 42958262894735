import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

const useGetWeeklyMealPlans = (startDate: string, endDate: string) => {
  const { getAccessTokenSilently } = useAuthentication();

  const {
    data,
    isError,
    isFetching,
    isInitialLoading: isLoading,
  } = useQuery(
    [queryKeys.mealPlans, startDate, endDate],
    async () => {
      await getAccessTokenSilently();
      return apiClient.getWeeklyMealPlans(startDate, endDate, undefined);
    },
    {
      onSuccess: (data) => {},

      onError: (error: AxiosError) => {
        console.log(error);
        logger.error(error, "Error retrieving weekly meal plans");

        toast.error(
          `Status: ${error?.response?.status}, Code: ${error?.code} - We ran into an error! Please try again - ${error}`
        );
      },
    }
  );

  return { isLoading, isError, data, isFetching };
};

export default useGetWeeklyMealPlans;
