import { Flex, IconButton, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

type Props = {
  footer: string;
  Icon: IconType;
  innerContent: string;
  onIncrease?: () => void;
  onDecrease?: () => void;
  servings?: number;
};

const MiniInformationCard = ({
  footer,
  Icon,
  innerContent,
  onIncrease,
  onDecrease,
  servings,
}: Props) => (
  <Flex flexDir={"column"}>
    {onIncrease && (
      <IconButton
        aria-label="Increase servings"
        icon={<AiOutlineArrowUp fontSize={20} />}
        colorScheme="brand"
        size="md"
        variant="ghost"
        onClick={onIncrease}
        isDisabled={servings === undefined}
      />
    )}
    <Flex
      flexDir={"column"}
      padding={"10px"}
      minW="75px"
      maxW="125px"
      //set a maxH  of 100px if you want it to grow. If not, just set it as a static height so they all look the same.
      h="90px"
      bgColor={"brand.100"}
      borderRadius="10px"
      marginBottom={"10px"}
      gap="5px"
      border={"2px solid #8952e0"}
      // justifyContent={"space-between"}
    >
      <Flex alignItems={"center"} gap="10px">
        <Flex>
          <Icon color="#8952e0" size="20px" />
        </Flex>
        <Flex>
          <Text fontWeight={"bold"} color="brand.700">
            {innerContent}
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent={"center"}>
        <Text fontSize={"md"} align="center">
          {footer}
        </Text>
      </Flex>
    </Flex>
    {onDecrease && (
      <IconButton
        aria-label="Decrease servings"
        icon={<AiOutlineArrowDown fontSize={20} />}
        size="md"
        colorScheme={"brand"}
        variant="ghost"
        onClick={onDecrease}
        isDisabled={servings === undefined || servings <= 1}
      />
    )}
  </Flex>
);

export default MiniInformationCard;
