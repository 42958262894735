import { Button } from "@chakra-ui/react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { brand500Color } from "../../pages/_app";

type Props = {
  isDisabled: boolean;
  size?: number;
  onClick?: () => void;
};

const AddToShoppingListButton = ({ isDisabled, size = 42, onClick }: Props) => {
  return (
    <Button
      mt="5px"
      isDisabled={isDisabled}
      leftIcon={<AiOutlineShoppingCart color={brand500Color} size={size} />}
      variant="unstyled"
      onClick={onClick}
    />
  );
};

export default AddToShoppingListButton;
