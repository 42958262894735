import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ShoppingListVm } from "../../apiClient/clients";
import {
  useCreateShoppingListForMealPlan,
  useGetWeeklyMealPlans,
} from "../../hooks";
import { AddButton } from "../Buttons";
import AddToShoppingListButton from "../Buttons/AddToShoppingListButton";
import MealPlannerSelectModal from "../Modals/MealPlannerSelectModal";

import _ from "lodash";

import moment from "moment";
import { useRouter } from "next/router";
import { LoadingSpinner } from "..";
import mealPlanSlotColors from "../../utils/mealPlanSlotColours";
import MealPlanRow from "../Cards/MealPlanRow";
import { ConfirmActionDialog } from "../Modals";

const getMealColorsForDate = (date: string, groupedData: any): string[] => {
  const mealsForDate = groupedData[date];

  if (!mealsForDate) return [];

  return mealsForDate.map((meal: any) => mealPlanSlotColors[meal.mealSlot]);
};

const WeeklyCalendar = () => {
  moment.locale("en-gb");
  const [createdShoppingList, setCreatedShoppingList] =
    useState<ShoppingListVm>();
  const [currentWeek, setCurrentWeek] = useState<Date[]>([]);
  const [manuallySelectedDate, setManuallySelectedDate] = useState<Date>();

  const [today, setToday] = useState(new Date());

  useEffect(() => {
    getWeek(new Date());
  }, []);

  const handleAddButton = () => {
    onOpen();
    setManuallySelectedDate(undefined);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCreateShoppingListOpen,
    onOpen: onShoppingListOpen,
    onClose: onShoppingListClose,
  } = useDisclosure();

  const {
    isOpen: isViewShoppingListOpen,
    onOpen: onViewShoppingListOpen,
    onClose: onViewShoppingListClose,
  } = useDisclosure();

  const handleDateClick = (day: Date) => {
    console.log(day);
    setManuallySelectedDate(day);
    onOpen();
  };

  const startWeekDate = moment(currentWeek[0]).format("YYYY-MM-DD");
  const endWeekDate = moment(currentWeek[6]).format("YYYY-MM-DD");

  const { push } = useRouter();

  const {
    data,
    isLoading: isGetWeeklyMealPlansLoading,
    isFetching,
  } = useGetWeeklyMealPlans(startWeekDate, endWeekDate);

  const handleCreateShoppingListForMealPlanSuccess = (
    shoppingListVm: ShoppingListVm
  ) => {
    setCreatedShoppingList(shoppingListVm);
    onViewShoppingListOpen();
  };

  const handleRedirectToShoppingList = () =>
    createdShoppingList && push(`/shopping-lists/${createdShoppingList.id}`);

  const {
    createShoppingListForMealPlan,
    isLoading: createShoppingListForMealPlanIsLoading,
  } = useCreateShoppingListForMealPlan({
    onSuccess: handleCreateShoppingListForMealPlanSuccess,
  });

  const isDataLoading =
    // isLoading ||
    isGetWeeklyMealPlansLoading ||
    isFetching ||
    // isGetUserLoading ||
    // isCloneRecipeLoading ||
    createShoppingListForMealPlanIsLoading;

  const handleCreateShoppingList = () => {
    createShoppingListForMealPlan({
      startDate: startWeekDate,
      endDate: endWeekDate,
    });
  };

  useEffect(() => {
    const id = moment(new Date()).format("dddd MMM D");
    const element = document.getElementById(id);
    if (element) {
      setTimeout(
        () =>
          element.scrollIntoView({
            block: "center",
            // inline: "center",
            behavior: "smooth",
            // alignToTop: false,
          }),

        0
      );
    }
  }, [data]);

  const getWeek = (referenceDate: Date) => {
    const startOfWeek =
      referenceDate.getDate() -
      referenceDate.getDay() +
      (referenceDate.getDay() === 0 ? -6 : 1);
    let week = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(referenceDate);
      day.setDate(startOfWeek + i);
      week.push(day);
    }

    setCurrentWeek(week);
  };

  const handlePreviousWeek = () => {
    let referenceDay = new Date(currentWeek[0]);
    referenceDay.setDate(referenceDay.getDate() - 7);
    getWeek(referenceDay);
  };

  const handleNextWeek = () => {
    let referenceDay = new Date(currentWeek[0]);
    referenceDay.setDate(referenceDay.getDate() + 7);
    getWeek(referenceDay);
  };

  const isToday = (day: Date) => {
    return (
      day.getFullYear() === today.getFullYear() &&
      day.getMonth() === today.getMonth() &&
      day.getDate() === today.getDate()
    );
  };

  const groupedData = _.groupBy(data, "mealDate");

  const hasMealPlans = data && data?.length > 0;

  return (
    <>
      <MealPlannerSelectModal
        isOpen={/*true*/ isOpen}
        onOpen={onOpen}
        onClose={onClose}
        manuallySelectedDate={manuallySelectedDate}
      />

      <ConfirmActionDialog
        isOpen={isCreateShoppingListOpen}
        onOpen={onShoppingListOpen}
        onClose={onShoppingListClose}
        onSubmit={handleCreateShoppingList}
        headerText="Create a shopping list for the current week?"
        bodyText="This will create a shopping list for the current week based on the
        recipes you have selected."
      />
      <ConfirmActionDialog
        isOpen={isViewShoppingListOpen}
        onOpen={onViewShoppingListOpen}
        onClose={onViewShoppingListClose}
        onSubmit={handleRedirectToShoppingList}
        headerText="View shopping list for the current week?"
        saveButtonText="View"
      />

      <Flex gap={5} flexDir="column">
        <Flex
          justifyContent={"center"}
          w="100%"
          backgroundColor={"gray.50"}
          zIndex="1000"
          position="sticky"
          top="0"
        >
          <Box
            border="1px"
            backgroundColor={"white"}
            borderColor="gray.200"
            w="lg"
            minW="250px"
            p={[4]}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Flex>
                <AddToShoppingListButton
                  isDisabled={!hasMealPlans || isDataLoading}
                  size={25}
                  onClick={() => onShoppingListOpen()}
                />
                <AddButton
                  isDisabled={isDataLoading}
                  size={25}
                  onClick={() => handleAddButton()}
                />
              </Flex>
              <Text fontWeight="bold">
                {currentWeek?.[0]?.toLocaleDateString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </Text>
              <Flex gap="5px">
                <Button onClick={handlePreviousWeek} variant="outline">
                  <Text fontWeight="bold">«</Text>
                </Button>
                <Button onClick={handleNextWeek} variant="outline">
                  <Text fontWeight="bold">»</Text>
                </Button>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" w="100%">
              {currentWeek.map((day, index) => {
                const formattedDate = moment(day).format("YYYY-MM-DDT00:00:00");
                const mealColors = getMealColorsForDate(
                  formattedDate,
                  groupedData
                );
                return (
                  <Box key={index} textAlign="center" w="50px">
                    <Text fontWeight="bold">
                      {day.toLocaleDateString("default", { weekday: "short" })}
                    </Text>
                    <Box textAlign={"center"} mt={2}>
                      {isToday(day) ? (
                        <Circle
                          bg="purple.500"
                          color="white"
                          borderRadius={"md"}
                        >
                          <Text
                            onClick={() => handleDateClick(day)}
                            cursor="pointer"
                          >
                            {day.getDate()}
                          </Text>
                        </Circle>
                      ) : (
                        // </Circle>
                        <Text
                          onClick={() => handleDateClick(day)}
                          cursor="pointer"
                        >
                          {day.getDate()}
                        </Text>
                      )}
                      <Flex mt={2} direction="row" justify="center">
                        {mealColors.map((color, i) => (
                          <Circle key={i} bg={color} size="8px" margin="2px" />
                        ))}
                      </Flex>
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>
        <Flex
          // w="lg"
          flexDir={"column"}
          // h="500px"
          // maxH={"60vh"}
          // overflowY="auto"
        >
          {isDataLoading && <LoadingSpinner />}
          {!isDataLoading && data?.length === 0 && (
            <Center ml={"5%"} mr="5%" flexDir={"column"} gap="20px">
              <AddButton
                isDisabled={false}
                size={40}
                onClick={() => handleAddButton()}
              />
              <Heading size="sm">
                No meals planned for this week. Add a meal to get started
              </Heading>
            </Center>
          )}

          {
            // 3. Map through each group and render.
            data &&
              data?.length > 0 &&
              Object.entries(groupedData).map(([date, mealPlans]) => (
                <Flex flexDir={"column"} gap="5px" key={date}>
                  <Heading size={"sm"} id={moment(date).format("dddd MMM D")}>
                    {moment(date).format("dddd MMM D")}
                  </Heading>
                  {mealPlans.map((mp) => (
                    <Flex key={mp.id}>
                      <MealPlanRow mealPlan={mp} key={mp.id} />
                    </Flex>
                  ))}
                </Flex>
              ))
          }
        </Flex>
      </Flex>
    </>
  );
};

export default WeeklyCalendar;
