import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { RecipeForm } from "../Forms";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle?: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
  recipeId: string;
};

const ViewRecipeFormModal = ({
  isOpen,
  onClose,
  onOpen,
  onCancel,
  onSubmit,
  recipeId,
}: Props) => {
  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay>
          <ModalContent w={["95%", "95%", "100%"]} h="80vh" overflowY={"auto"}>
            <RecipeForm
              recipeId={recipeId}
              formMode="read"
              onlyShowCloseButton
              onlyShowCloseButtonOnClick={onClose}
            />
            {/* <ShoppingListForm
              setIsCreateShoppingListApiLimitError={setApiLimitError}
              formMode="create"
              onBack={() => {
                onClose();
                onCancel && onCancel();
              }}
              onSave={() => {
                onClose();
                onSubmit();
              }}
              recipeId={recipeId}
            /> */}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default ViewRecipeFormModal;
