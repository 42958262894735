import { Flex } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";
import { CreateRecipeCommand } from "../../../apiClient/clients";
import { NumberInput } from "../../Inputs";
import { OptionType } from "./RecipeIngredientForm";

type Props = {
  index: number;
  isReadOnly?: boolean;
};

const fractionOptions = [
  "1/8",
  "1/4",
  "1/3",
  "3/8",
  "1/2",
  "5/8",
  "2/3",
  "3/4",
  "7/8",
];

const fractionSelectOptions: OptionType[] = [
  ...fractionOptions.map((f) => ({ value: f, label: f })),
];

const RecipeIngredientQuantity = ({ index, isReadOnly = false }: Props) => {
  const {
    control,
    register,
    formState: { errors, defaultValues, dirtyFields },
    watch,
  } = useFormContext<CreateRecipeCommand>();

  const watchIngredients = watch(`ingredients.${index}.quantityIsFraction`); // when pass nothing as argument, you are watching everything

  return (
    <Flex gap="20px" w="100%" flexWrap={"wrap"}>
      {watchIngredients == true ? (
        <>
          <Controller
            name={`ingredients.${index}.quantityNumerator`}
            control={control}
            render={({ field }) => (
              <NumberInput
                isReadOnly={isReadOnly}
                allowDecimals={false}
                field={field}
                h="100%"
                w="40%"
                placeholder="Quantity"
                errorMessage={
                  errors.ingredients?.[index]?.quantity?.message &&
                  "Quantity is invalid"
                }
              />
            )}
          />
          <Controller
            control={control}
            name={`ingredients.${index}.quantityDenominator`}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { error },
            }) => (
              <div style={{ width: "40%" }}>
                <Select
                  isClearable
                  focusBorderColor="brand.500"
                  defaultInputValue={value || ""}
                  placeholder="Fraction"
                  variant={"flushed"}
                  name={name}
                  ref={ref}
                  onChange={(value) => onChange(value?.value)}
                  onBlur={onBlur}
                  options={fractionSelectOptions}
                  selectedOptionColorScheme="brand"

                  // closeMenuOnSelect={false}
                />
              </div>
            )}
          />
        </>
      ) : (
        <Controller
          name={`ingredients.${index}.quantity`}
          control={control}
          render={({ field }) => (
            <NumberInput
              isReadOnly={isReadOnly}
              allowDecimals={true}
              field={field}
              h="100%"
              placeholder="Quantity"
              errorMessage={
                errors.ingredients?.[index]?.quantity?.message &&
                "Quantity is invalid"
              }
            />
          )}
        />
      )}
    </Flex>
  );
};

export default RecipeIngredientQuantity;
