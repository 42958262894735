import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle?: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
  headerText: string;
  bodyText?: string;
  saveButtonText?: string;
};

const ConfirmActionDialog = ({
  headerText,
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  bodyText,
  saveButtonText = "Create",
}: Props) => {
  const cancelRef = React.useRef() as any;

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>

            {bodyText && <AlertDialogBody>{bodyText}</AlertDialogBody>}

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose();
                  onCancel && onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brand"
                onClick={() => {
                  onClose();
                  onSubmit();
                }}
                ml={3}
              >
                {saveButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ConfirmActionDialog;
