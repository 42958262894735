import * as yup from "yup";

const recipeSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(100)
    .typeError("Name must provided and at least 3 characters"),
  description: yup.string().nullable().max(250),
  ingredients: yup.array().of(
    yup.object().shape({
      name: yup.string().required().min(3).max(100).label("Ingredient"),
    })
  ),
  instructions: yup.array().of(
    yup.object().shape({
      name: yup.string().required().min(3).max(300).label("Instruction"),
    })
  ),
  link: yup.string().nullable().max(200),
  recipeNote: yup.string().nullable().max(750),
  prepTime: yup.object().shape({
    hours: yup
      .number()
      .nullable()
      .min(0)
      .typeError("Must be a whole number, for example 15."),
    minutes: yup
      .number()
      .nullable()
      .min(0)
      .max(60)
      .typeError("Must be a whole number, for example 15."),
  }),
  cookTime: yup.object().shape({
    hours: yup
      .number()
      .nullable()
      .min(0)
      .typeError("Must be a whole number, for example 15."),
    minutes: yup
      .number()
      .nullable()
      .min(0)
      .max(60)
      .typeError("Must be a whole number, for example 15."),
  }),
  servings: yup
    .number()
    .nullable()
    .typeError("Must be a whole number, for example 15."),
  nutritionInformation: yup.object().shape({
    calories: yup.number().nullable(),
    protein: yup.number().nullable(),
    carbohydrates: yup.number().nullable(),
    fats: yup.number().nullable(),
  }),
});

export default recipeSchema;
