import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-photo-view/dist/react-photo-view.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
var pluralize = require("pluralize");

import { useRouter } from "next/router";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import {
  CreateRecipeCommand,
  RecipeVm,
  UpdateRecipeCommand,
} from "../../../apiClient/clients";
import {
  useAuthentication,
  useCheckUserHasRecipeAdded,
  useCloneRecipe,
  useUpdateRecipe,
} from "../../../hooks";
import useCreateRecipe, {
  RecipeRequest,
} from "../../../hooks/recipes/useCreateRecipe";
import useGetSingleRecipe from "../../../hooks/recipes/useGetSingleRecipe";
import { FileWithErrorMessage } from "../../../types";
import FileUploaderV3 from "../../FileUploader/FileUploaderv3";
import { AutoTextArea, NumberInput } from "../../Inputs";
import AutoTextarea from "../../Inputs/TextArea";
import TextInput from "../../Inputs/TextInput";
import RecipeIngredientForm from "./RecipeIngredientForm";
import RecipeInstructionForm from "./RecipeInstructionForm";
import recipeSchema from "./recipeSchema";

import { useSearchParams } from "next/navigation";
import {
  AiFillCheckCircle,
  AiOutlineClockCircle,
  AiOutlineCopy,
} from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { sessionStorageKeys } from "../../../config";
import { firebaseLogScreenView } from "../../../config/firebase";
import { UpdateRecipeRequest } from "../../../hooks/recipes/useUpdateRecipe";
import useGetUser from "../../../hooks/users/useGetUser";
import {
  handleApiLimitError,
  imageSizeQueryParams,
  ProblemDetails,
} from "../../../utils";
import { SignInButton } from "../../Buttons";
import MiniInformationCard from "../../Cards/MiniInformationCard";
import { ImageCarousel } from "../../Carousels";
import EmptyImagePlaceholder from "../../FileUploader/EmptyImagePlaceholder";
import { LoadingSpinner } from "../../LoadingSpinner";
import { RecipeMenu } from "../../Menus";
import { UpgradeModal } from "../../Modals";
import { NutritionInfoChart } from "../../NutritionInfoChart";

type Props = {
  formMode: FormMode;
  recipeId?: string;
  isEditMode?: boolean;
  isAccessedFromCollectionPage?: boolean;
  onBackUrl?: string;
  onlyShowCloseButton?: boolean;
  onlyShowCloseButtonOnClick?: () => void;
};

export type FormMode = "create" | "read" | "edit";

const RecipeForm = ({
  recipeId,
  formMode,
  isAccessedFromCollectionPage,
  onBackUrl,
  onlyShowCloseButton = false,
  onlyShowCloseButtonOnClick,
}: Props) => {
  const [customServings, setCustomServings] = useState<number | undefined>(
    undefined
  );
  const [apiLimitError, setApiLimitError] = useState<string | undefined>();
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleAddApiLimitError = (err: ProblemDetails) => {
    handleApiLimitError(err, setApiLimitError, onOpenUpgradeModal);
  };

  const [files, setFiles] = useState<FileWithErrorMessage[]>([]);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  const { isLoading: loading, isAuthenticated } = useAuthentication();

  const searchParams = useSearchParams();

  const isEditedFromRecipeCollection = searchParams.get("recipeCollection");

  const { isLoading: isGetUserLoading, data: userData } =
    useGetUser(isAuthenticated);

  const router = useRouter();

  //is this still required? test new recipe > back > new recipe (and upload photos on the first one and see what happens)
  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount

  //   setFiles([]);
  // }, []);

  const onSuccess = () => {
    //If we'rre editing from a recipe collection - return back to the recipe.

    if (isEditedFromRecipeCollection) {
      router.back();
    }

    //Only boot back to /recipes if we're modifying from the /recipes page. Else do nothing
    if (!isAccessedFromCollectionPage) {
      router.push("/recipes");
    }
  };

  const onUpdateSuccess = (res?: RecipeVm) => {
    //If we'rre editing from a recipe collection - return back to the recipe.

    if (isEditedFromRecipeCollection) {
      router.back();
    }

    //Only boot back to /recipes if we're modifying from the /recipes page. Else do nothing
    if (!isAccessedFromCollectionPage) {
      router.push(`/recipes/${res?.id}`);
    }
  };

  const handleIncreaseServings = () => {
    setCustomServings((prevServings) => (prevServings || 0) + 1);
  };

  const handleDecreaseServings = () => {
    setCustomServings((prevServings) => Math.max((prevServings || 0) - 1, 1));
  };

  const isReadMode = formMode === "read";

  const textInputIsRequired = isReadMode ? false : true;

  const { createRecipeMutate, isLoading: isCreateRecipeLoading } =
    useCreateRecipe({ onSuccess, onError: handleAddApiLimitError });

  const { updateRecipeMutate, isLoading: isUpdateRecipeLoading } =
    useUpdateRecipe({ onSuccess: onUpdateSuccess });

  const { cloneRecipeMutate, isLoading: isCloneRecipeLoading } = useCloneRecipe(
    { onSuccess }
  );

  const persistScrollPositionRecipes = (id: string) => {
    localStorage.setItem(sessionStorageKeys.recipeIdMarker, id);
  };

  const persistScrollPositionRecipeCollections = (id: string) => {
    localStorage.setItem(sessionStorageKeys.recipeCollectionIdMarker, id);
  };

  const {
    data,
    isError,
    isLoading: isGetRecipeLoading,
    isFetching,
  } = useGetSingleRecipe(isAuthenticated, loading, recipeId);

  useEffect(() => {
    setCustomServings(data?.servings);
  }, [data]);

  const {
    data: doesUserHaveRecipeAdded,
    isLoading: isCheckUserHasRecipeAddedCheckLoading,
  } = useCheckUserHasRecipeAdded(loading, recipeId, isAuthenticated);

  useEffect(() => {
    if (data?.recipeImages?.length && recipeId) {
      const recipeFiles: FileWithErrorMessage[] = data.recipeImages.map(
        (r) => ({
          imageId: r.imageId,
          //TODO: Use variants here.
          storageUrl: r.cdnUrl + imageSizeQueryParams(300, 300),
        })
      );
      setFiles(recipeFiles);
    }
  }, [recipeId, data?.recipeImages]);

  //Temporarily to handle the scenario where users were signed in before the objectId was added. If objectId is empty, return true.
  const isCreatedByCurrentUser = !userData?.id
    ? true
    : data?.createdByUserId === userData?.id;

  const methods = useForm<RecipeVm>({
    defaultValues: {},
    resolver: yupResolver(recipeSchema),
    // mode: "onChange",
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (data && recipeId) {
      reset({ ...data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return <LoadingSpinner />;
  }

  //Only trigger on read mode - the others will be handled in the authenticated page
  if (formMode == "read") {
    if (!isAuthenticated) {
      firebaseLogScreenView(
        "ViewSingleRecipeUnAuthenticated",
        "ViewSingleRecipeUnAuthenticated"
      );
      if (isAccessedFromCollectionPage) {
        firebaseLogScreenView(
          "ViewSingleRecipeCollectionRecipeUnAuthenticated",
          "ViewSingleRecipeCollectionRecipeUnAuthenticated"
        );
      }
    } else {
      firebaseLogScreenView(
        "ViewSingleRecipeAuthenticated",
        "ViewSingleRecipeAuthenticated"
      );
      if (isAccessedFromCollectionPage) {
        firebaseLogScreenView(
          "ViewSingleRecipeCollectionRecipeAuthenticated",
          "ViewSingleRecipeCollectionRecipeAuthenticated"
        );
      }
    }
  }

  const isLoading =
    isCreateRecipeLoading ||
    isGetRecipeLoading ||
    isCloneRecipeLoading ||
    isUpdateRecipeLoading ||
    isFetching;

  const onUpdate: SubmitHandler<RecipeVm> = (data) => {
    const imageFormData = new FormData();
    //HACK: Nswag doesn't generate the File type properly, therefore need to do this hack until a better solution is found
    // data.recipeImage = files as any;
    if (files.length) {
      // if (files.length >= 3) {
      //   toast.error(
      //     "A maximum of three files can be uploaded. The first three selected photos have been added"
      //   );
      // }
      for (let i = 0; i < files.length; i++) {
        const element = files[i];

        // if (files.length >= 3) {
        //   toast.error("A maximum of three files can be uploaded.");
        //   return;
        // }

        //if we're uploading a new photo - attach the file.
        //Else if we're using existing photos that are already uploaded, just reference the ID.
        if (element.file) {
          imageFormData.append(`recipeImage${i}`, element.file);
          imageFormData.append(`order-recipeImage${i}`, i.toString());
        } else if (element.imageId) {
          imageFormData.append(
            element.imageId || `recipeImage${i}`,
            element.imageId
          );
          imageFormData.append(
            "order-" + element.imageId || `recipeImage${i}`,
            i.toString()
          );
        }
      }
    }
    if (!recipeId) {
      return;
    }

    data.ingredients?.forEach((ingredient, index) => {
      ingredient.displayOrder = index;
    });

    data.instructions?.forEach((instructions, index) => {
      instructions.displayOrder = index;
    });

    const updateRecipeCommand: UpdateRecipeCommand = {
      id: recipeId,
      name: data.name,
      description: data.description,
      recipeNote: data.recipeNote,
      link: data.link,
      difficulty: data.difficulty,
      ingredients: data.ingredients,
      instructions: data.instructions,
      nutritionInformation: data.nutritionInformation,
      cookTime: {
        hours: data.cookTime?.hours,
        minutes: data.cookTime?.minutes,
      },
      prepTime: {
        hours: data.prepTime?.hours,
        minutes: data.prepTime?.minutes,
      },
      servings: data.servings,
    };

    const recipeRequest: UpdateRecipeRequest = {
      updateRecipeCommand,
    };

    //TODO: This is kind of ugly but because azure functions doesn't have [FromForm] and some problems with NSwag.... This seems like the best approach.
    //always send the imageFormData so it triggers even if null. (so then the API clears images if empty)
    recipeRequest.imageFormData = imageFormData;
    updateRecipeMutate(recipeRequest);
  };

  const onSubmit: SubmitHandler<RecipeVm> = (data) => {
    const imageFormData = new FormData();

    //HACK: Nswag doesn't generate the File type properly, therefore need to do this hack until a better solution is found
    // data.recipeImage = files as any;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        if (element.file) {
          imageFormData.append(`recipeImage${i}`, element.file);
          imageFormData.append(`order-recipeImage${i}`, i.toString());
        }
      }
    }

    data.ingredients?.forEach((ingredient, index) => {
      ingredient.displayOrder = index;
    });

    data.instructions?.forEach((instructions, index) => {
      instructions.displayOrder = index;
    });

    const createRecipeCommand: CreateRecipeCommand = {
      name: data.name,
      description: data.description,
      link: data.link,
      difficulty: data.difficulty,
      ingredients: data.ingredients,
      instructions: data.instructions,
      recipeNote: data.recipeNote,
      nutritionInformation: data.nutritionInformation,
      cookTime: {
        hours: data.cookTime?.hours,
        minutes: data.cookTime?.minutes,
      },
      prepTime: {
        hours: data.prepTime?.hours,
        minutes: data.prepTime?.minutes,
      },
      servings: data.servings,
    };

    const recipeRequest: RecipeRequest = {
      createRecipeCommand,
    };

    //TODO: This is kind of ugly but because azure functions doesn't have [FromForm] and some problems with NSwag.... This seems like the best approach.
    files.length && (recipeRequest.imageFormData = imageFormData);
    createRecipeMutate(recipeRequest);
  };

  const handleFileOnChange = (e?: ChangeEvent<HTMLInputElement>) => {
    let isAlertModalActive = false;
    const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    e?.preventDefault();
    const onChangeFiles = e?.target.files;

    if (onChangeFiles) {
      if (files.length >= 3) {
        toast.error("A maximum of three files can be uploaded.");
        return;
      }

      for (let i = 0; i < onChangeFiles.length; ++i) {
        const file = onChangeFiles[i];
        const newFile: FileWithErrorMessage = { file };
        newFile.preview = URL.createObjectURL(file);
        if (
          (file?.size && file.size > 20000000) ||
          (file?.type && !acceptedFileTypes.includes(file?.type))
        ) {
          newFile.errorMessage =
            "File must be less than 20 MB and be a valid image file - png, jpeg/jpg";
        }
        if (i === 3 || files.length >= 3) {
          //Only allow 3 images to be uploaded
          toast.error(
            `A max of three photos can be uploaded - the first three have been added.`
          );

          return;
        }
        if (newFile.errorMessage) {
          toast.error(
            "File must be less than 20 MB and be a valid image file - png, jpeg/jpg. Skipping this file. "
          );
        }
        //TODO: Test Error validation.
        //display the error message if it exists and let the user remove it.
        setFiles((prevArray) => {
          if (prevArray.length >= 3) {
            //TODO This displays twice if you select two photos, and then e.g. if you select two more.
            //maybe look into using some type of state / something else.
            if (!isAlertModalActive) {
              isAlertModalActive = true;

              toast.error(
                `A max of three photos can be uploaded - the first three have been added.`
              );
            }

            return prevArray;
          }
          return [...prevArray, newFile];
        });
      }
    }
  };

  const handleOnFileRemove = (file: FileWithErrorMessage) => {
    const foundFile = files.find(
      (f) => f.file == file.file || f.imageId == file.imageId
    );
    if (foundFile) {
      let previousArray = files;
      previousArray = previousArray.filter(
        (f) => f.file != file.file || f.imageId !== file.imageId
      );
      setFiles([...previousArray]);
    }
  };

  if (recipeId && (isGetRecipeLoading || isFetching)) {
    return <LoadingSpinner />;
  }

  const EditButton = () => (
    <Button
      mt={4}
      colorScheme="brand"
      isLoading={isLoading}
      onClick={() => {
        //If we're editing a recipe collection - we want the user to be referred back to the collection - Not the /recipes page
        //Therefore just append a recipeCollection query param. If true, then dont boot them back.
        let url = `/recipes/${recipeId}/edit`;
        isAccessedFromCollectionPage && (url += "?recipeCollection=true");
        router.push(url);
      }}
      type="button"
    >
      Edit
    </Button>
  );

  const AddToMyRecipesButton = () => (
    // console.log(doesUserHaveRecipeAdded);
    //TODO: see if we can removet his <p>? seems weird.
    //What we want to do:
    //If the current authenticated user, who hasn't created the recipe.
    //Check to see if they've already added this recipe to their recipe list. using: ClonedFromRecipeId
    //If so, show the Added similar to the joined.
    <p>
      <Button
        mt={4}
        colorScheme="brand"
        isLoading={isLoading || isCheckUserHasRecipeAddedCheckLoading}
        isDisabled={doesUserHaveRecipeAdded}
        onClick={() => {
          recipeId && cloneRecipeMutate(recipeId);
          localStorage.removeItem("redirectSlug");
        }}
        type="button"
      >
        {doesUserHaveRecipeAdded ? (
          <Flex gap="10px">
            <AiFillCheckCircle color="green" /> Saved
          </Flex>
        ) : (
          "Save to my recipes"
        )}
      </Button>
    </p>
  );

  const ActionButtons = () => {
    //Show a sign up button if they're not auth'd

    if (onlyShowCloseButton) {
      return (
        <Button
          mt={4}
          colorScheme="brand"
          isLoading={isLoading}
          type="submit"
          onClick={() =>
            onlyShowCloseButtonOnClick && onlyShowCloseButtonOnClick()
          }
          // form="RecipeForm"
        >
          Close
        </Button>
      );
    }
    if (!isAuthenticated) {
      return (
        <SignInButton
          mt={4}
          isLoading={isLoading}
          text={"Sign up"}
          returnTo={router.asPath}
        />
      );
    }

    //If the form is in reaad mode - if the user created it, show edit button. Else show add to my recipes button
    if (isReadMode) {
      return isCreatedByCurrentUser ? <EditButton /> : <AddToMyRecipesButton />;
    }

    return (
      <Button
        mt={4}
        colorScheme="brand"
        isLoading={isLoading}
        type="submit"
        form="RecipeForm"
      >
        Save
      </Button>
    );
  };

  //TODO: Text Input's throw controlled/uncontrolled errors whne using controller, but NumberINput's don't 👀
  return (
    <>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />
      <FormProvider {...methods}>
        <Flex pr="25px" direction="column">
          {data &&
            isCreatedByCurrentUser &&
            isAuthenticated &&
            !onlyShowCloseButton && (
              <Flex justifyContent={"end"}>
                <RecipeMenu
                  recipe={data}
                  isAccessedFromCollectionPage={isAccessedFromCollectionPage}
                  onDelete={() => router.push("/recipes")}
                />
              </Flex>
            )}
        </Flex>
        <Flex h="100%" direction={"column"}>
          <form
            onKeyPress={(e) => {
              if (e.key === "enter") {
                e.preventDefault();
              }
            }}
            // style={{ height: "100%" }}
            id="RecipeForm"
            onSubmit={
              recipeId ? handleSubmit(onUpdate) : handleSubmit(onSubmit)
            }
          >
            <Flex p="25px" direction="column" gap="10px" height="100%">
              {/*//TODO: Fix all of the gaps / padding etc. Use a flex on the Form and
        just set a gap there.*/}
              {(formMode === "create" || formMode === "edit") && (
                <FileUploaderV3
                  errorMessage={errors.recipeImages?.message}
                  displayUploadPhotoButton={!isReadMode}
                  onFileChange={handleFileOnChange}
                  files={files}
                  onFileRemove={handleOnFileRemove}
                />
              )}

              {data?.recipeImages?.length && formMode === "read" ? (
                <ImageCarousel recipeImages={data?.recipeImages} />
              ) : (
                <></>
              )}

              {!data?.recipeImages?.length &&
              formMode === "read" &&
              !isGetRecipeLoading ? (
                <EmptyImagePlaceholder />
              ) : (
                <></>
              )}

              {isReadMode ? (
                <>
                  <Text align="center" fontWeight={"bold"} fontSize="xl">
                    {data?.name}
                  </Text>
                  <Divider />
                  <Text>{data?.description}</Text>
                  <Flex
                    flexDir={"row"}
                    gap="10px"
                    flexWrap={"wrap"}
                    alignItems="center"
                  >
                    {(data?.prepTime?.hours || data?.prepTime?.minutes) && (
                      <MiniInformationCard
                        footer="Prep"
                        Icon={AiOutlineClockCircle}
                        innerContent={
                          `${
                            data?.prepTime?.hours
                              ? `${data?.prepTime?.hours}h `
                              : ""
                          }` +
                          `${
                            data?.prepTime?.minutes
                              ? `${data?.prepTime?.minutes}m`
                              : ""
                          }`
                        }
                      />
                    )}
                    {(data?.cookTime?.hours || data?.cookTime?.minutes) && (
                      <MiniInformationCard
                        footer="Cook"
                        Icon={AiOutlineClockCircle}
                        innerContent={
                          `${
                            data?.cookTime?.hours
                              ? `${data?.cookTime?.hours}h `
                              : ""
                          }` +
                          `${
                            data?.cookTime?.minutes
                              ? `${data?.cookTime?.minutes}m`
                              : ""
                          }`
                        }
                      />
                    )}
                    {data?.servings && (
                      <MiniInformationCard
                        footer="Servings"
                        Icon={BsFillPeopleFill}
                        innerContent={`${customServings ?? data.servings}`}
                        onIncrease={handleIncreaseServings}
                        onDecrease={handleDecreaseServings}
                        servings={customServings ?? data.servings}
                      />
                    )}
                    {(data?.nutritionInformation?.calories ||
                      data?.nutritionInformation?.carbohydrates ||
                      data?.nutritionInformation?.fats ||
                      data?.nutritionInformation?.protein) && (
                      <NutritionInfoChart recipe={data} />
                    )}
                  </Flex>
                  {data?.link && (
                    <FormControl>
                      {<FormLabel>Link</FormLabel>}
                      <Flex gap="5px" align="center">
                        <AiOutlineCopy
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(data.link || "");
                            toast.success("Copied to clipboard!");
                          }}
                          style={{
                            color: "#8952e0",
                            fontSize: "26px",
                            cursor: "pointer",
                          }}
                          color="#8952e0"
                          size="26px"
                        />

                        <Input
                          isReadOnly={isReadMode}
                          focusBorderColor="brand.500"
                          as={"input"}
                          value={data?.link}
                          variant="flushed"
                        />
                      </Flex>
                    </FormControl>
                  )}
                </>
              ) : (
                <>
                  <TextInput
                    isReadOnly={isReadMode}
                    variant="flushed"
                    // variant={textInputVariant}
                    {...register("name")}
                    isRequired={textInputIsRequired}
                    label="Name"
                    placeholder={isReadMode ? "" : "Recipe Name"}
                    errorMessage={errors.name?.message}
                  />
                  <AutoTextarea
                    {...register("description")}
                    placeholder="Recipe Description"
                    isReadOnly={isReadMode}
                    variant="flushed"
                    errorMessage={errors.description?.message}
                    label="Description"
                  />
                  <Flex wrap={"wrap"} w="100%" gap="25px">
                    <Flex>
                      <Flex flexDirection="column">
                        <FormLabel>Prep Time</FormLabel>
                        <Flex gap="10px">
                          <Controller
                            name={"prepTime.hours"}
                            control={control}
                            render={({ field }) => (
                              <NumberInput
                                w={"100px"}
                                placeholder="Hours"
                                isReadOnly={isReadMode}
                                allowDecimals={false}
                                field={field}
                                errorMessage={errors.prepTime?.hours?.message}
                              />
                            )}
                          />
                          <Text fontWeight={"bold"} fontSize="xl">
                            :
                          </Text>
                          <Controller
                            name={"prepTime.minutes"}
                            control={control}
                            render={({ field }) => (
                              <NumberInput
                                placeholder="Minutes"
                                w={"100px"}
                                isReadOnly={isReadMode}
                                allowDecimals={false}
                                field={field}
                                errorMessage={errors.prepTime?.minutes?.message}
                                max={60}
                              />
                            )}
                          />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex>
                      <Flex flexDirection="column">
                        <FormLabel>Cook Time</FormLabel>
                        <Flex gap="10px">
                          <Controller
                            name={"cookTime.hours"}
                            control={control}
                            render={({ field }) => (
                              <NumberInput
                                w={"100px"}
                                placeholder="Hours"
                                isReadOnly={isReadMode}
                                allowDecimals={false}
                                field={field}
                                errorMessage={errors.cookTime?.hours?.message}
                              />
                            )}
                          />
                          <Text fontWeight={"bold"} fontSize="xl">
                            :
                          </Text>
                          <Controller
                            name={"cookTime.minutes"}
                            control={control}
                            render={({ field }) => (
                              <NumberInput
                                placeholder="Minutes"
                                w={"100px"}
                                isReadOnly={isReadMode}
                                allowDecimals={false}
                                field={field}
                                errorMessage={errors.cookTime?.minutes?.message}
                                max={60}
                              />
                            )}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Controller
                    name={"servings"}
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        isReadOnly={isReadMode}
                        allowDecimals={false}
                        field={field}
                        placeholder="servings"
                        errorMessage={errors.servings?.message}
                        label="Servings"
                      />
                    )}
                  />
                  <Flex>
                    <Flex flexDirection="column">
                      <FormLabel>Nutrition Information - Per Serving</FormLabel>
                      <Flex gap="25px" flexWrap={"wrap"}>
                        <Controller
                          name={"nutritionInformation.calories"}
                          control={control}
                          render={({ field }) => (
                            <NumberInput
                              w={"100px"}
                              placeholder="Calories"
                              label="Calories"
                              isReadOnly={isReadMode}
                              allowDecimals={true}
                              field={field}
                              errorMessage={
                                errors.nutritionInformation?.calories?.message
                              }
                            />
                          )}
                        />
                        <Controller
                          name={"nutritionInformation.protein"}
                          control={control}
                          render={({ field }) => (
                            <NumberInput
                              w={"100px"}
                              placeholder="Protein"
                              isReadOnly={isReadMode}
                              allowDecimals={true}
                              field={field}
                              label="Protein (g)"
                              errorMessage={
                                errors.nutritionInformation?.protein?.message
                              }
                            />
                          )}
                        />
                        <Controller
                          name={"nutritionInformation.carbohydrates"}
                          control={control}
                          render={({ field }) => (
                            <NumberInput
                              w={"100px"}
                              placeholder="Carbs"
                              label="Carbs (g)"
                              isReadOnly={isReadMode}
                              allowDecimals={true}
                              field={field}
                              errorMessage={
                                errors.nutritionInformation?.carbohydrates
                                  ?.message
                              }
                            />
                          )}
                        />

                        <Controller
                          name={"nutritionInformation.fats"}
                          control={control}
                          render={({ field }) => (
                            <NumberInput
                              w={"100px"}
                              placeholder="Fats"
                              label="Fats (g)"
                              isReadOnly={isReadMode}
                              allowDecimals={true}
                              field={field}
                              errorMessage={
                                errors.nutritionInformation?.fats?.message
                              }
                            />
                          )}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <TextInput
                    {...register("link")}
                    label="Link"
                    isReadOnly={isReadMode}
                    variant={"flushed"}
                    placeholder="A link to where you found this recipe"
                    errorMessage={errors.link?.message}
                  />
                  <AutoTextarea
                    {...register("recipeNote")}
                    placeholder="Notes"
                    isReadOnly={isReadMode}
                    variant="flushed"
                    errorMessage={errors.recipeNote?.message}
                    label="Notes"
                  />
                </>
              )}

              <RecipeIngredientForm
                isReadOnly={isReadMode}
                recipe={data}
                customServings={customServings}
              />
              <RecipeInstructionForm isReadOnly={isReadMode} />
              {data?.recipeNote && isReadMode && (
                <FormControl>
                  {<FormLabel>Notes</FormLabel>}
                  <Flex gap="5px" align="center">
                    <AutoTextArea
                      variant={"flushed"}
                      isReadOnly={isReadMode}
                      value={data?.recipeNote}
                    />
                  </Flex>
                </FormControl>
              )}
            </Flex>
          </form>
          <Flex
            justifyContent={"end"}
            gap="15px"
            pos={"sticky"}
            bottom="0"
            bg={"white"}
            p="10px"
            boxShadow={"0px 0 2px rgba(0, 0, 0, 0.8)"}
            marginTop="auto"
          >
            {(isAuthenticated || isAccessedFromCollectionPage) &&
              !onlyShowCloseButton && (
                <Button
                  mt={4}
                  colorScheme="brand"
                  isLoading={isLoading}
                  variant="outline"
                  onClick={() => {
                    const url = onBackUrl ? onBackUrl : "/recipes";
                    try {
                      localStorage.removeItem("redirectSlug");
                    } catch (error) {}

                    /**
                     * https://blog.logrocket.com/implementing-scroll-restoration-in-ecommerce-react-apps/
                     * If the user created the recipe (meaning it's in their list of recipes)
                     * When they press Back, persist the ID so it will route back to that recipe.
                     * This is for the scenario where they're viewing all recipes, Click on a recipe. Press back
                     * It will take them back to where they were previously scrolling to.
                     * TODO: This should support when a user creates it.
                     */
                    /**NOTE:
                     * now that we support featured recipes, a user may not always have created the recipe.
                     * Therefore, only store it / persist scroll position IF they're authenticated
                     * i.e., if they're publically browsing - dont persist.
                     */
                    if (
                      (url == "/recipes" || onBackUrl == "/admin") &&
                      /*data?.createdByUserId == userData?.id*/ isAuthenticated &&
                      recipeId
                    ) {
                      persistScrollPositionRecipes(recipeId);
                    }

                    //If accessed via recipe collection, persistScrollPosition

                    if (isAccessedFromCollectionPage && recipeId) {
                      persistScrollPositionRecipeCollections(recipeId);
                    }

                    return router.push(url);
                  }}
                >
                  Back
                </Button>
              )}
            <ActionButtons />
          </Flex>
        </Flex>
      </FormProvider>
    </>
  );
};

export default RecipeForm;
