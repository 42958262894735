import { useDisclosure } from "@chakra-ui/react";
import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  RecipeCollectionWithRecipesVm,
  RecipeVm,
} from "../../apiClient/clients";
import { useAuthentication, useGetShoppingLists } from "../../hooks";
import useRemoveRecipeFromRecipeCollection from "../../hooks/recipeCollections/useRemoveRecipeFromRecipeCollection";
import useGetUser from "../../hooks/users/useGetUser";
import {
  handleApiLimitError,
  ProblemDetails,
  writeToClipboard,
} from "../../utils";
import MenuTextItem from "../Cards/MenuTextItem";
import { UpgradeModal } from "../Modals";
import DeleteAlertDialog from "../Modals/DeleteAlertDialog";

type Props = {
  recipe: RecipeVm;
  isAccessedFromCollectionPage?: boolean;
  onDelete?: () => void;
  recipeCollection?: RecipeCollectionWithRecipesVm;
};

const RecipeCollectionRecipeCardMenu = ({
  recipe,
  isAccessedFromCollectionPage,
  recipeCollection,
  onDelete,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const [apiLimitError, setApiLimitError] = useState<string | undefined>();

  const { isLoading: loading, isAuthenticated } = useAuthentication();

  //TODO: Can pass this in from the pages to stop it loading on every onOpen.
  const { isFetching: isGetUserLoading, data: getUserData } =
    useGetUser(shouldFetchData);

  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = useDisclosure();

  const handleAddApiLimitError = (err: ProblemDetails) => {
    handleApiLimitError(err, setApiLimitError, onOpenUpgradeModal);
  };

  const isCurrentUserRecipeCollectionCreator =
    recipeCollection?.createdByUserId == getUserData?.id;

  const showDeleteButton =
    isAuthenticated && isCurrentUserRecipeCollectionCreator;

  const isDataLoading = isGetUserLoading;

  const { removeRecipeFromRecipeCollection, isLoading: isDeleteLoading } =
    useRemoveRecipeFromRecipeCollection({});

  const handleCancelDelete = () => console.log("cancel");

  const handleSubmitDelete = () => {
    onDelete && onDelete();
    if (recipeCollection?.id) {
      removeRecipeFromRecipeCollection({
        recipeId: recipe.id,
        recipeCollectionId: recipeCollection?.id,
      });
    }
  };

  const {
    data: shoppingListData,
    isLoading: isGetShoppingListLoading,
    isFetching,
  } = useGetShoppingLists(undefined, shouldFetchData);

  return (
    <>
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onOpen={onOpenUpgradeModal}
        onClose={onCloseUpgradeModal}
        onCancel={onCloseUpgradeModal}
        apiLimitError={apiLimitError}
      />
      <DeleteAlertDialog
        deleteMessage="Remove Recipe From Collection"
        deleteButtonText="Remove"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
      />
      <Menu
        onClick={(e) => {
          e.stopPropagation();
        }}
        unmountOnClose={true}
        initialMounted={false}
        menuStyle={{ maxWidth: "250px" }}
        menuButton={
          <MenuButton
            style={{ height: "25px", width: "50px" }}
            onClick={(e) => {
              e.stopPropagation();
              //We dont want to load the APi data from the collcection page, since we dont have the shopping list etc on that page.
              //Also only fetch if they're authenticated.
              if (!isAccessedFromCollectionPage && isAuthenticated) {
                setShouldFetchData(true);
              }
            }}
          >
            <BsThreeDotsVertical style={{ marginLeft: "25px" }} />
          </MenuButton>
        }
      >
        <MenuItem
          onClick={() => {
            writeToClipboard(`recipes/${recipe.id}`);
          }}
        >
          <MenuTextItem
            icon={<AiOutlineLink size="20px" />}
            text="Copy Share Link"
          />
        </MenuItem>
        {isAuthenticated && isCurrentUserRecipeCollectionCreator && (
          <>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                onOpen();
              }}
            >
              <MenuTextItem
                icon={<RiDeleteBin5Line color="red" />}
                text="Remove from collection"
              />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default RecipeCollectionRecipeCardMenu;
