import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { RecipeVm } from "../../apiClient/clients";
import { firebaseLogEvent } from "../../config/firebase";
import logger from "../../utils/logger";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: (list: RecipeVm) => void;
  onError?: () => void;
};

const useImportFromInstagram = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: importFromInstagramMutate } = useMutation(
    async (url: string) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("import_recipe", { recipe_url: url });
      // toast.loading(
      //   "We're currently processing this recipe. it can take between 30-60 seconds to generate it. Keep this page open and we'll let you know when it's ready!"
      // );

      return apiClient.generateRecipeFromInstagramUrl(url, undefined);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipes],
        });
        toast.dismiss();
        toast.success(`Created Recipe: ${res.name}`, { duration: 7000 });
        onSuccess && onSuccess(res);
      },
      onError: (err) => {
        toast.dismiss();
        //TODO: Display validation errors from the BE if they exist.
        logger.error(err, "There was an error generating this recipe.");
        toast.error(`There was an error generating this recipe - ${err}`);
      },
    }
  );

  return { isLoading, importFromInstagramMutate };
};

export default useImportFromInstagram;
