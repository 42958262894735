import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { RecipeVm } from "../../apiClient/clients";
import { sessionStorageKeys } from "../../config";
import { useDebounce, useGetRecipes } from "../../hooks";
import { getSortByOptionFromSessionStorage, sortOptions } from "../../utils";
import { RecipeRow } from "../Cards";
import FeaturedRecipesCheckbox from "../Checkbox/FeaturedRecipesCheckbox";
import { OptionType } from "../Forms/RecipeForm/RecipeIngredientForm";
import { LoadingSpinner } from "../LoadingSpinner";
import ReusableGrid from "../ReusableGrid/ReusableGrid";

type Props = {
  isOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  setSelectedRecipe: Dispatch<SetStateAction<RecipeVm | undefined>>;
  selectedRecipe?: RecipeVm;
};

const SelectRecipeModal = ({
  isOpen,
  setModalOpen,
  setSelectedRecipe,
  selectedRecipe,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<OptionType>(
    getSortByOptionFromSessionStorage(
      sessionStorageKeys.recipesMealPlannerSortBy
    ) || sortOptions[0]
  );

  const debouncedValue = useDebounce(searchValue, 500);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetRecipes(debouncedValue, selectedOption.value, undefined, isOpen);

  useEffect(() => {
    if (debouncedValue) {
      inputRef.current?.focus();
    }
  }, [isFetching, debouncedValue]);

  useEffect(() => {
    setSearchValue("");
  }, [isOpen]);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => setModalOpen(false)}
      autoFocus={false}
    >
      <ModalOverlay>
        <ModalContent w="90%" maxW="xl" p="5" h={"85vh"} maxH="xl">
          <Flex
            flexDirection="column"
            w="100%"
            // justifyContent="center"
            // alignItems="center"
            gap="15px"
            h="100%"
          >
            <Text textAlign={"center"} fontSize={"lg"} fontWeight={"bold"}>
              Select Recipe
            </Text>
            <InputGroup>
              <Input
                m="5px"
                focusBorderColor="brand.500"
                placeholder="Search Recipes"
                bg={"white"}
                size="lg"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                autoFocus={false}
              />
              <InputRightElement h={"full"}>
                {searchValue && (
                  <IconButton
                    _hover={{ cursor: "pointer" }}
                    aria-label="Clear Field"
                    size="xl"
                    icon={<IoIosCloseCircle size={"24px"} />}
                    onClick={() => setSearchValue("")}
                    variant="unstyled"
                  />
                )}
              </InputRightElement>
            </InputGroup>
            <Flex gap="5px">
              <div style={{ width: "200px", marginLeft: 5 }}>
                <Select
                  useBasicStyles
                  chakraStyles={{
                    container: (provided) => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                    option: (provided, { isSelected }) => ({
                      ...provided,

                      // ...(isSelected && {
                      //   backgroundColor: "white",
                      // }),
                    }),
                  }}
                  focusBorderColor="brand.500"
                  selectedOptionColorScheme="brand"
                  value={selectedOption}
                  colorScheme="brand.500"
                  name={"sort-by-select"}
                  options={sortOptions}
                  onChange={(option) => {
                    option?.value &&
                      localStorage.setItem(
                        sessionStorageKeys.recipesMealPlannerSortBy,
                        option?.value
                      );
                    setSelectedOption(option as OptionType);
                  }}
                />
              </div>
              <FeaturedRecipesCheckbox />
            </Flex>

            <Flex
              flexDir={"column"}
              h="500px"
              //   maxH={["45vh", "45vh"]}
              overflowY="auto"
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <ReusableGrid
                  dataLength={data?.pages.flat().length}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={!!hasNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  displayStyle="list"
                >
                  {data?.pages?.map((page) =>
                    page.map((d) => (
                      <Flex key={d.id}>
                        <RecipeRow
                          key={d.id}
                          recipe={d}
                          setSelectedRecipe={setSelectedRecipe}
                          isSelected={selectedRecipe?.id == d.id}
                        />
                      </Flex>
                    ))
                  )}
                </ReusableGrid>
              )}
            </Flex>
            <Flex justifyContent={"end"} gap="15px">
              <Button
                mt={4}
                colorScheme="brand"
                isLoading={false}
                variant="outline"
                onClick={() => {
                  setModalOpen(false);
                  setSelectedRecipe(undefined);
                }}
              >
                Back
              </Button>
              <Button
                mt={4}
                colorScheme="brand"
                isDisabled={!selectedRecipe}
                isLoading={false}
                onClick={() => {
                  if (selectedRecipe) {
                    setSelectedRecipe(selectedRecipe);
                    setModalOpen(false);
                  }
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default SelectRecipeModal;
