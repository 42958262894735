import { useMutation } from "@tanstack/react-query";
import apiClient from "../../apiClient/apiClient";
import { CheckShoppingListIngredientCommand } from "../../apiClient/clients";
import { firebaseLogEvent } from "../../config/firebase";
import logger from "../../utils/logger";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useCheckShoppingListIngredient = ({ onSuccess, onError }: Props) => {
  const { isLoading, mutate: checkShoppingListIngredientMutate } = useMutation(
    async ({
      shoppingListId,
      ingredientId,
      isChecked,
      duplicateIngredients,
    }: {
      shoppingListId: string;
      ingredientId: string;
      isChecked: boolean;
      duplicateIngredients?: string[];
    }) => {
      await firebaseLogEvent("check_shopping_list_ingredient", {
        ingredient_id: ingredientId,
        shopping_list_id: shoppingListId,
      });

      return apiClient.checkShoppingListIngredient(
        shoppingListId,
        ingredientId,
        isChecked,
        { duplicateIngredients } as CheckShoppingListIngredientCommand,
        undefined
      );
    },
    {
      onSuccess: async (res) => {
        // await queryClient.invalidateQueries({
        //   queryKey: [queryKeys.shoppingLists],
        // });

        // toast.success("Added recipe to shopping list! 📝");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        logger.error(err, "Error checking the shopping list ingredient");
        // toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, checkShoppingListIngredientMutate };
};

export default useCheckShoppingListIngredient;
