import { Button, Flex, Heading } from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import "react-photo-view/dist/react-photo-view.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { UpdateIngredientCommand } from "../../../apiClient/clients";
import { useUpdateIngredient } from "../../../hooks/ingredients";
import { OptionType } from "../RecipeForm/RecipeIngredientForm";

type Props = {
  onBack?: () => void;
  onSave?: () => void;
  ingredientId: string;
  defaultCategory?: Category;
  shoppingListId: string;
};

export type Category =
  | "Fruits, Vegetables and Herbs"
  | "Meats and Seafood"
  | "Dairy and Grains"
  | "Pantry Items"
  | "Snacks and Beverages"
  | "Seasonings"
  | undefined;

type FormInputs = {
  category: Category;
};

const categoryOptions = [
  "Fruits, Vegetables and Herbs",
  "Meats and Seafood",
  "Dairy and Grains",
  "Pantry Items",
  "Snacks and Beverages",
  "Seasonings",
  "Other",
];

const categorySelectOptions: OptionType[] = [
  ...categoryOptions.map((f) => ({ value: f, label: f })),
];

const AddIngredientCategoryForm = ({
  onBack,
  onSave,
  ingredientId,
  shoppingListId,
  defaultCategory,
}: Props) => {
  const { updateIngredient, isLoading } = useUpdateIngredient({
    shoppingListId,
    onSuccess: () => {
      onSave && onSave();
    },
  });

  const methods = useForm<FormInputs>({
    defaultValues: { category: defaultCategory || undefined },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const onSubmit: SubmitHandler<FormInputs> = (data, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    const command: UpdateIngredientCommand = {
      id: ingredientId,
      category: data.category,
    };
    updateIngredient(command);
  };

  const ActionButtons = () => {
    return (
      <Flex padding={"0px 10px 0px 0px"} justify={"end"} gap="25">
        <Button
          mt={4}
          colorScheme="brand"
          isLoading={isLoading}
          variant="outline"
          onClick={onBack}
        >
          Cancel
        </Button>
        <Button
          mt={4}
          colorScheme="brand"
          isLoading={isLoading}
          type="submit"
          //   form="SaveIngredientCategory"
          isDisabled={!isDirty}
        >
          Save
        </Button>
      </Flex>
    );
  };

  //TODO: Text Input's throw controlled/uncontrolled errors whne using controller, but NumberINput's don't 👀
  return (
    <FormProvider {...methods}>
      <Flex h="100%" direction={"column"} p="5">
        <Heading size="md">Ingredient Category</Heading>

        <form
          onKeyPress={(e) => {
            if (e.key === "enter") {
              e.preventDefault();
            }
          }}
          // style={{ height: "100%" }}
          //   id="SaveIngredientCategory"
          //   onSubmit={handleSubmit(onSubmit)}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Flex p="25px" direction="column" gap="10px" height="100%">
            {/*//TODO: Fix all of the gaps / padding etc. Use a flex on the Form and
        just set a gap there.*/}
            <Controller
              control={control}
              name={"category"}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                // <div style={{ width: "40%" }}>
                <Select
                  isClearable
                  focusBorderColor="brand.500"
                  defaultInputValue={value || ""}
                  filterOption={() => true}
                  placeholder="Category"
                  variant={"flushed"}
                  name={name}
                  ref={ref}
                  onChange={(value) => onChange(value?.value)}
                  onBlur={onBlur}
                  options={categorySelectOptions}
                  selectedOptionColorScheme="brand"

                  // closeMenuOnSelect={false}
                />
                // </div>
              )}
            />
          </Flex>
          <ActionButtons />
        </form>
      </Flex>
    </FormProvider>
  );
};

export default AddIngredientCategoryForm;
