import {
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar, AiOutlineSearch } from "react-icons/ai";
import { MealPlanSlot, RecipeVm } from "../../apiClient/clients";
import { useCloneRecipe, useCreateMealPlan } from "../../hooks";
import useGetUser from "../../hooks/users/useGetUser";
import { brand500Color } from "../../pages/_app";
import { RecipeRow } from "../Cards";
import SelectRecipeModal from "./SelectRecipeModal";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  recipe?: RecipeVm;
  manuallySelectedDate?: Date;
  hideSelectRecipe?: boolean;
  // setManuallySelectedDate: Dispatch<SetStateAction<Date | undefined>>;
};

const MealPlannerSelectModal = ({
  isOpen,
  onClose,
  recipe,
  manuallySelectedDate,
  hideSelectRecipe = false,
}: // setManuallySelectedDate,
Props) => {
  const options = [
    { value: MealPlanSlot.Breakfast, label: "Breakfast" },
    { value: MealPlanSlot.Lunch, label: "Lunch" },
    { value: MealPlanSlot.Snack, label: "Snack" },
    { value: MealPlanSlot.Dinner, label: "Dinner" },
    { value: MealPlanSlot.Dessert, label: "Dessert" },
    { value: MealPlanSlot.Other, label: "Other" },
  ];

  const [selectedMeal, setSelectedMeal] = useState<MealPlanSlot>(
    MealPlanSlot.Dinner
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    if (manuallySelectedDate) {
      setSelectedDate(manuallySelectedDate);
    } else {
      setSelectedDate(new Date());
    }
  }, [manuallySelectedDate]);

  const [innerModalOpen, setInnerModalOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<RecipeVm | undefined>(
    recipe
  );

  const { createMealPlan, isLoading } = useCreateMealPlan({});

  const { isLoading: isGetUserLoading, data: getUserData } = useGetUser(true);

  const handleSuccess = (res: RecipeVm) => {
    createMealPlan({
      mealSlot: selectedMeal,
      mealDate: moment(selectedDate).format("YYYY-MM-DD"),
      recipeId: res.id,
    });
  };

  const { cloneRecipeMutate, isLoading: isCloneRecipeLoading } = useCloneRecipe(
    { onSuccess: handleSuccess }
  );

  const handleSelectRecipe = (
    selectedMeal: MealPlanSlot,
    selectedDate: Date,
    selectedRecipe: RecipeVm
  ) => {
    //If featured recipe = save it first.
    //Then pass the data into the onsuccess to create the plan.
    // we want the user check, to make sure it only tries if the user didnt create the featured recipe
    if (
      selectedRecipe.isGloballyVisible &&
      selectedRecipe.createdByUserId !== getUserData?.id
    ) {
      cloneRecipeMutate(selectedRecipe.id);
    } else {
      createMealPlan({
        mealSlot: selectedMeal,
        mealDate: moment(selectedDate).format("YYYY-MM-DD"),
        recipeId: selectedRecipe.id,
      });
    }
  };

  type CustomButtonDatePickerInputProps = {
    value?: string;
    onClick?: () => void;
  };

  const CustomButtonDatePickerInput = forwardRef<
    HTMLButtonElement,
    CustomButtonDatePickerInputProps
  >(({ value, onClick }, ref) => (
    <Button
      leftIcon={<AiOutlineCalendar color={brand500Color} />}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      variant="outline"
      colorScheme={"brand"}
      style={{ width: "100%" }}
    >
      {value}
    </Button>
  ));

  CustomButtonDatePickerInput.displayName = "CustomButtonDatePickerInput";

  return (
    <>
      <Modal
        autoFocus={false}
        size="xl"
        isOpen={isOpen && !innerModalOpen}
        onClose={() => {
          onClose();
          if (!recipe) {
            setSelectedRecipe(undefined);
          }
        }}
      >
        <ModalOverlay>
          <ModalContent w={["90%", "90%", "100%"]} maxW="xl" p="5">
            <Flex
              flexDirection="column"
              w="100%"
              // justifyContent="center"
              // alignItems="center"
              gap="15px"
            >
              <Text textAlign={"center"} fontSize={"lg"} fontWeight={"bold"}>
                Add Recipe
              </Text>
              <Select
                options={options}
                onChange={(option) =>
                  setSelectedMeal(option?.value || options[0].value)
                }
                focusBorderColor="brand.500"
                placeholder="Select Meal slot"
                variant={"flushed"}
                // defaultInputValue={options[3].label}
                // defaultValue={options[3]} // Sets "Dinner" as the default selected value
                selectedOptionColorScheme="brand"
                value={options.find((o) => o.value === selectedMeal)}
              />

              <DatePicker
                className="date-picker"
                icon={<AiOutlineCalendar />}
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date as Date);
                  // if (date) {
                  //   setManuallySelectedDate(date);
                  // } else {
                  //   setManuallySelectedDate(undefined);
                  // }
                }}
                dateFormat={selectedDate?.toLocaleDateString(undefined)}
                onFocus={(e) => e.target.blur()}
                customInput={<CustomButtonDatePickerInput />}

                // disabledKeyboardNavigation
              />

              {!hideSelectRecipe && (
                <Button
                  variant="outline"
                  colorScheme={"brand"}
                  onClick={() => {
                    setInnerModalOpen(true);
                    setSelectedRecipe(undefined);
                  }}
                  leftIcon={<AiOutlineSearch size={20} />}
                >
                  Select Recipe
                </Button>
              )}

              {selectedRecipe && (
                <RecipeRow
                  recipe={selectedRecipe}
                  setSelectedRecipe={setSelectedRecipe}
                  isSelected={false}
                  hideCursor
                />
              )}
              <Flex justifyContent={"end"} gap="15px">
                <Button
                  mt={4}
                  colorScheme="brand"
                  isLoading={false}
                  variant="outline"
                  onClick={() => {
                    onClose();
                    if (!recipe) {
                      setSelectedRecipe(undefined);
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  mt={4}
                  colorScheme="brand"
                  isLoading={false}
                  isDisabled={!selectedDate || !selectedRecipe || !selectedMeal}
                  type="submit"
                  form="ShoppingListForm"
                  onClick={() => {
                    if (selectedDate && selectedRecipe) {
                      handleSelectRecipe(
                        selectedMeal || MealPlanSlot.Dinner,
                        selectedDate,
                        selectedRecipe
                      );
                      onClose();
                      if (!recipe) {
                        setSelectedRecipe(undefined);
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      {/* This would be your inner modal */}
      <SelectRecipeModal
        isOpen={innerModalOpen}
        setModalOpen={setInnerModalOpen}
        setSelectedRecipe={setSelectedRecipe}
        selectedRecipe={selectedRecipe}
      />
    </>
  );
};

export default MealPlannerSelectModal;
