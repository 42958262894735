import {
  Badge,
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Shimmer } from "react-shimmer";
import { MealPlanVm } from "../../apiClient/clients";
import { useDeleteMealPlan } from "../../hooks";
import getImageFromRecipe from "../../utils/getImageFromRecipe";
import mealPlanSlotColors from "../../utils/mealPlanSlotColours";
import { ViewRecipeFormModal } from "../Modals";
import DeleteAlertDialog from "../Modals/DeleteAlertDialog";

type Props = {
  mealPlan: MealPlanVm;
};

const MealPlanRow = ({ mealPlan }: Props) => {
  const { recipe } = mealPlan;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRecipeOpen,
    onOpen: onRecipeOpen,
    onClose: onRecipeClose,
  } = useDisclosure();

  const currentMealSlotColor = mealPlanSlotColors[mealPlan.mealSlot];

  const { deleteMealPlan, isLoading } = useDeleteMealPlan({});

  const handleDelete = () => {
    deleteMealPlan(mealPlan.id);
  };

  if (!recipe) {
    return <></>;
  }

  return (
    <>
      <ViewRecipeFormModal
        recipeId={recipe.id}
        isOpen={isRecipeOpen}
        onOpen={onRecipeOpen}
        onClose={onRecipeClose}
        onSubmit={() => console.log("")}
      />
      <DeleteAlertDialog
        entityTypeToDelete="Item From Meal Plan"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onSubmit={handleDelete}
      />

      <Flex
        bg={"white"}
        p={4}
        rounded="lg"
        shadow="md"
        alignItems="center"
        w="full"
        mb={4} // margin-bottom for spacing between rows
        cursor={"pointer"}
        onClick={() => {
          onRecipeOpen();
        }}
      >
        <Box bg={currentMealSlotColor} w="4px" h="full" mr={4}></Box>

        <Box flexShrink={0} mr={4}>
          <Image
            boxSize="85px"
            borderRadius="md"
            src={getImageFromRecipe({
              sizeOption: "500_500",
              recipeImage: recipe.recipeImages?.[0],
            })}
            alt={recipe.name}
            fallback={
              <Flex justify={"center"} height="100%" w="100%">
                <Shimmer
                  height={200}
                  width={200}
                  className="shimmer-max-width"
                />
              </Flex>
            }
          />
        </Box>
        <Flex flexDirection="column" flexGrow={1}>
          <Flex>
            <Text noOfLines={2} fontWeight="bold" color={currentMealSlotColor}>
              {mealPlan.mealSlot}
            </Text>
            <IconButton
              background={"transparent"}
              icon={<RiDeleteBin5Line color="red" size="22px" />}
              aria-label="Delete"
              // isDisabled={isLoading}
              isLoading={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
              ml="auto" // To push the icon to the far right
            />
          </Flex>
          <Text noOfLines={2} fontWeight="bold" mb={2}>
            {recipe.name}
          </Text>
          {recipe.isGloballyVisible && (
            <Badge w="70px" marginBottom={"1"} colorScheme="green">
              Featured
            </Badge>
          )}
          {/* <Flex>
            {recipe.prepTime?.hours ||
              recipe.prepTime?.minutes ||
              recipe.cookTime?.hours ||
              (recipe.cookTime?.minutes && (
                <>
                  <Text mr={2}>{`${recipe.prepTime?.hours || "00"}:${
                    recipe.prepTime?.minutes || "00"
                  }`}</Text>
                  <Text mr={2}>{`${recipe.cookTime?.hours || "00"}:${
                    recipe.cookTime?.minutes || "00"
                  }`}</Text>
                </>
              ))}
          </Flex> */}
        </Flex>
      </Flex>
    </>
  );
};

export default MealPlanRow;
