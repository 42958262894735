import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { CreateRecipeCommand, RecipeVm } from "../../apiClient/clients";
import uploadImage from "../../apiClient/uploadImage";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import logger from "../../utils/logger";
import reactQueryOnApiLimitError from "../../utils/reactQueryOnApiLimitError";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

//NOTE: This is a workaround for azure functions not supporting [FromForm] as well as NSwag issues.
//Essentially, when the request succeeds fire off a request to  upload the image.

export type RecipeRequest = {
  createRecipeCommand: CreateRecipeCommand;
  imageFormData?: FormData;
};

type Props = {
  onSuccess?: (recipe?: RecipeVm) => void;
  onError?: (err: ProblemDetails) => void;
};

const useCreateRecipe = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: createRecipeMutate } = useMutation(
    async ({ createRecipeCommand, imageFormData }: RecipeRequest) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("create_recipe");

      //NOTE: This is not ideal.

      const createRecipe = await apiClient.createRecipe(createRecipeCommand);
      if (imageFormData) {
        const toastId = toast.loading("Uploading images... 📷");
        try {
          await uploadImage(
            imageFormData,
            createRecipe.id,
            (progressEvent: any) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              toast.loading(`Uploading images... ${percentCompleted}%`, {
                id: toastId,
              });
            }
          );
        } catch (error) {
          console.error(
            `Failed to upload images for recipeId: ${createRecipe.id}`
          );
          console.error(error);
          logger.error(
            `Failed to upload images for recipeId: ${createRecipe.id}`
          );
          toast.error(
            "We ran into an error uploading images. The recipe has been created."
          );
        } finally {
          toast.dismiss(toastId);
        }
      }
      return createRecipe;
    },
    {
      onSuccess: async (res) => {
        //NOTE: not ideal
        // imageFormData && (await uploadImage(imageFormData, res.id));
        await queryClient.invalidateQueries({ queryKey: [queryKeys.recipes] });

        toast.success("Created recipe! 👨‍🍳");
        onSuccess && onSuccess(res);
      },
      onError: (err: any) => reactQueryOnApiLimitError(err, onError),
    }
  );

  return { isLoading, createRecipeMutate };
};

export default useCreateRecipe;
