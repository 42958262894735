const mealPlanSlotColors: { [key: string]: string } = {
  Breakfast: "red.400",
  Snack: "yellow.400",
  Lunch: "blue.400",
  Dinner: "green.400",
  Dessert: "purple.400",
  Other: "gray.400",
};

export default mealPlanSlotColors;
