import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useRemoveRecipeFromRecipeCollection = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: removeRecipeFromRecipeCollection } = useMutation(
    async ({
      recipeId,
      recipeCollectionId,
    }: {
      recipeId: string;
      recipeCollectionId: string;
    }) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("remove_recipe_from_recipe_collection", {
        recipe_id: recipeId,
        recipe_collection_id: recipeCollectionId,
      });

      return apiClient.removeRecipeFromRecipeCollection(
        recipeCollectionId,
        recipeId,
        undefined
      );
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipeCollections],
        });
        toast.success("Recipe removed from collection");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, removeRecipeFromRecipeCollection };
};

export default useRemoveRecipeFromRecipeCollection;
