import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { CreateMealPlanCommand, MealPlanVm } from "../../apiClient/clients";
import { firebaseLogEvent } from "../../config/firebase";
import { ProblemDetails } from "../../utils";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: (mealPlan: MealPlanVm) => void;
  onError?: (err: ProblemDetails) => void;
};

const useCreateMealPlan = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: createMealPlan } = useMutation(
    async (command: CreateMealPlanCommand) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("create_meal_plan");
      return apiClient.createMealPlan(command);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.mealPlans],
        });

        toast.success("Created meal plan! 📝");
        onSuccess && onSuccess(res);
      },
      // onError: (err: any) => reactQueryOnApiLimitError(err, onError),
      onError: (err: any) => {
        toast.error("Sorry, we ran into an error");
        console.error(err);
      },
    }
  );

  return { isLoading, createMealPlan };
};

export default useCreateMealPlan;
