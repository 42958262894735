import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IngredientVm } from "../../apiClient/clients";
import { useCheckShoppingListIngredient } from "../../hooks";
import BorderedCheckbox from "./BorderedCheckbox";

type Props = {
  shoppingListId: string;
  ingredientId: string;
  checked: boolean;
  text?: string;
  duplicateIngredients?: IngredientVm[];
  isIngredientView?: boolean;
};

const ShoppingListItemCheckbox = ({
  shoppingListId,
  ingredientId,
  checked,
  text,
  duplicateIngredients,
  isIngredientView = false,
}: Props) => {
  const { checkShoppingListIngredientMutate, isLoading } =
    useCheckShoppingListIngredient({});

  const [isChecked, setIsChecked] = useState(false);

  //We are passing in a checked from react-hook-form, which gets it from the ShoppingListVm.
  //Whenever that changes, it wasnt triggering a refresh.
  //So whenever the props change, call our hook to update the isChecked data.
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <BorderedCheckbox
      w={"100%"}
      isChecked={isChecked}
      onChange={(e) => {
        checkShoppingListIngredientMutate({
          shoppingListId,
          ingredientId,
          isChecked: e.target.checked,
          duplicateIngredients: duplicateIngredients?.map((i) => i.id),
        });
        setIsChecked(e.target.checked);
      }}
    >
      {isChecked ? (
        <Text textDecoration={"line-through"}>{text}</Text>
      ) : (
        <Text>{text}</Text>
      )}
    </BorderedCheckbox>
  );
};

export default ShoppingListItemCheckbox;
