import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { isNative } from "../utils";

/**
 *
 * @param redirectPath
 * @summary redirectPath shoudl be e.g. /recipes/recipeId. not the FQDN
 * IT MUST START WITH A SLASH!!!!
 */

/**
 * How this works.
 * From the page where a smartbanner is visible, we setup a URL on the button. it will have either an ios or android redirect query param
 * the first UEF runs, checks if it contains a fbclid. if it does, forces a reload and removes it (this is the fb messenger window, which breaks it)
 * Then - we hit the next UEF where not native, and its either ios redirect or android redirect
 * We call the redirectAppStore
 * This first tries to redirect the current page, which tries to trigger the iOS deep linking. If not, it redirects to the app store.
 *
 */

const useRedirectToIosApp = (redirectPath: string) => {
  if (!redirectPath.startsWith("/")) {
    console.error("Redirect path must start with a slash.");
  }
  const searchParams = useSearchParams();

  const isIosRedirect = searchParams.get("is_ios_redirect");
  const isAndroidRedirect = searchParams.get("is_android_redirect");

  const fbclid = searchParams.get("fbclid");

  const router = useRouter();

  /**
   * There seems to be some weird bug in the messenger / facebook's in-app browser, where the fbclid breaks the universal linking 50% of the time.
   * So, if there's a fbclid present. Replace the window. and redirect to www.
   * Then - if someone presses the button, they'l be redirected to the app if installed.
   * Else they'll be redirected to the app store.
   * Everything works as expected in chrome, instagram etc.
   */
  useEffect(() => {
    //Attempt to remove all query params if a fbclid is present in case thats messing with the IOS redirect.
    //this can probably check for any query params

    if (fbclid && !isNative) {
      window.location.replace(`https://www.recipelog.app${redirectPath}`);
      // router.reload();
      // router.push(`/recipes/${recipeId}`, undefined, {
      //   shallow: true,
      // });
    }
  }, [fbclid]);

  useEffect(() => {
    //If the user presses "Open" from the smart banner, and they dont have the app installed. Redirect them to the ios app store to download the app.
    //Once the app is installed, any time they press open it will use smart links to open the app directly.
    //IF THEY'RE NOT NATIVE!
    //The smart banner only shows if they're not native. We then remove the query param so nobody can share it and then get people to install accidentally.

    if (!isNative && (isIosRedirect == "true" || isAndroidRedirect == "true")) {
      console.log("not native");
      console.log("Redirected from IOS", isIosRedirect);
      console.log("Redirected from android", isAndroidRedirect);

      const redirectAppStore = async () => {
        await new Promise((r) => setTimeout(r, 500));
        try {
          //This will be If the device is android.
          // if (isAndroidRedirect == "true") {
          //   // const intent = `intent://${redirectPath}#Intent;scheme=https;package=app.recipelog.www.twa;end`;
          //   const intent = `intent://www.recipelog.app/recipes/7c723869-d078-401e-8a2e-08db5acde065#Intent;scheme=https;package=app.recipelog.www.twa;end`;
          //   // window.location.replace(`https://recipelog.app/${redirectPath}`);

          //   // Try to open the app using the intent URL
          //   window.location.href = `intent://recipelog.app/recipes/7c723869-d078-401e-8a2e-08db5acde065#Intent;scheme=https;package=app.recipelog.www.twa;end`;

          //   // Fallback to the Play Store after a short delay
          //   setTimeout(() => {
          //     window.location.href =
          //       "https://play.google.com/store/apps/details?id=app.recipelog.www.twa";
          //   }, 500);

          //   //   // window.location.replace(intent);
          //   //   // setTimeout(() => {
          //   //   //   window.location.replace(
          //   //   //     "https://play.google.com/store/apps/details?id=app.recipelog.www.twa"
          //   //   //   );
          //   //   // }, 500);
          // } else {
          router.replace(redirectPath, undefined, { shallow: true });
          // }
          // setTimeout(function () {
          //   window.location.replace(alternateUrl);
          // }, 25);
          // window.location = customUrl;
          // recipelogapp;
        } catch (error) {
          console.error("failed to remove query param");
        }

        if (isIosRedirect == "true") {
          window.location.replace(
            "https://apps.apple.com/app/id6449183570?platform=iphone"
          );
        }

        if (isAndroidRedirect == "true") {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=app.recipelog.www.twa"
          );
        }
      };

      redirectAppStore();
    }
  }, [isIosRedirect, isAndroidRedirect]);
};

export default useRedirectToIosApp;
