import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useRouter } from "next/router";
import { useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Shimmer } from "react-shimmer";
import { RecipeCollectionVm } from "../../apiClient/clients";
import useDeleteRecipeCollection from "../../hooks/recipeCollections/useDeleteRecipeCollection";
import useGetUser from "../../hooks/users/useGetUser";
import { writeToClipboard } from "../../utils";
import getImageFromRecipe from "../../utils/getImageFromRecipe";
import DeleteAlertDialog from "../Modals/DeleteAlertDialog";
import MenuTextItem from "./MenuTextItem";
var pluralize = require("pluralize");

type Props = {
  recipeCollection: RecipeCollectionVm;
};

const RecipeCollectionCard = ({ recipeCollection }: Props) => {
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasUploadedImage =
    recipeCollection.thumbnailRecipe?.recipeImages?.[0]?.cdnUrl;
  const thumbnailRecipe = recipeCollection.thumbnailRecipe?.recipeImages?.[0];
  const { deleteRecipeCollection, isLoading } = useDeleteRecipeCollection({});

  const handleCancelDelete = () => console.log("cancel");

  const handleSubmitDelete = () =>
    deleteRecipeCollection(recipeCollection.id as string);

  const { isFetching: isGetUserLoading, data: getUserData } =
    useGetUser(shouldFetchData);

  const isCurrentUserRecipeCollectionCreator =
    recipeCollection?.createdByUserId == getUserData?.id;

  const router = useRouter();
  return (
    <>
      <DeleteAlertDialog
        entityTypeToDelete="Recipe Collection"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onCancel={handleCancelDelete}
        onSubmit={handleSubmitDelete}
      />
      {/* <div id={recipeCollection.id} style={{ height: "350px" }}> */}
      <Card
        h="325px" //TODO:If grid breaks -  Added a max height.
        borderRadius={"lg"}
        variant="outline"
        bg="white"
        onClick={() => router.push(`collections/${recipeCollection.id}`)}
        maxW="xs"
        size="sm"
        shadow={"md"}
        //   bg={useColorModeValue("gray.50", "gray.900")}
        // bg="unset"
        border={"3px solid transparent"}
        // _hover={{
        //   cursor: "pointer",
        //leaving these commented out as they kind of jank up scrolling on mobile (on ios app)
        //   transform: "translateY(-5px) scale(1.005) translateZ(0)",
        //   // border: "3px solid",
        //   //TODO: Get Theme here.
        //   // borderColor: "purple",
        // }}
      >
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Box>
                <Heading wordBreak={"break-word"} noOfLines={2} size="sm">
                  {recipeCollection.name}
                </Heading>
                <Heading noOfLines={2} size="sm">
                  <Flex fontWeight={"semibold"}>
                    {recipeCollection.recipeCount || 0}{" "}
                    {pluralize("recipe", recipeCollection.recipeCount || 0)}
                  </Flex>
                </Heading>
              </Box>
            </Flex>
            <Menu>
              <MenuButton
                as={IconButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShouldFetchData(true);
                }}
                variant="ghost"
                colorScheme="gray"
                aria-label="See menu"
                icon={<BsThreeDotsVertical />}
              />
              <MenuList>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    writeToClipboard(`collections/${recipeCollection.id}`);
                  }}
                  icon={<AiOutlineLink />}
                >
                  <Text>Copy Share Link</Text>
                </MenuItem>
                {isCurrentUserRecipeCollectionCreator && (
                  <>
                    <MenuDivider />
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpen();
                      }}
                    >
                      <MenuTextItem
                        icon={<RiDeleteBin5Line color="red" />}
                        text="Delete Recipe Collection"
                      />
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Flex>
          <Divider />
        </CardHeader>

        <Stack divider={<StackDivider />} spacing="4">
          <CardBody>
            <Flex justify={"center"}>
              {/* <Image
                // minH={"100px"}
                maxH="200px"
                // maxW={["150px", "200px", "300px"]}
                maxW={["140px", "200px"]}
                src={
                  recipe.recipeImages?.[0]?.url
                    ? recipe.recipeImages?.[0].url
                    : "/CookingLogo.png"
                }
                alt={"Recipe Image"}
                borderRadius="lg"
                objectFit="contain"
              /> */}
              {/* <LazyLoad> */}
              <Image
                h={hasUploadedImage ? "200px" : "200px"}
                w={hasUploadedImage ? "100%" : "200px"}
                src={getImageFromRecipe({
                  sizeOption: "500_500",
                  recipeImage: thumbnailRecipe,
                })}
                alt="Recipe Image"
                objectFit={"cover"}
                borderRadius={"lg"}
                justifyContent="center"
                fallback={<Shimmer height={200} width={250} />}
                loading="lazy"
              />
              {/* </LazyLoad> */}
            </Flex>
          </CardBody>
        </Stack>
      </Card>
      {/* </div> */}
    </>
  );
};

export default RecipeCollectionCard;
