import { Flex, Text } from "@chakra-ui/react";
import { useUpdateUser } from "../../hooks";
import useGetUser from "../../hooks/users/useGetUser";
import { LoadingSpinner } from "../LoadingSpinner";
import BorderedCheckbox from "./BorderedCheckbox";

type Props = {
  text?: string;
};

const FeaturedRecipesCheckbox = ({ text = "Featured" }: Props) => {
  const { isLoading: isGetUserLoading, data: userData } = useGetUser(true);
  const { isUpdateUserLoading, updateUser } = useUpdateUser({});
  const isDisabled = isGetUserLoading || isUpdateUserLoading;
  return (
    <>
      <BorderedCheckbox
        // w={"100%"}
        marginLeft={"5px"}
        isDisabled={isDisabled}
        isChecked={userData?.includeGlobalRecipes || false}
        onChange={(e) => {
          updateUser({
            includeGlobalRecipes: e.target.checked,
          });
        }}
      >
        <Flex>
          <Text>{text}</Text>
          {/* {isGetUserLoading || (true && <LoadingSpinner />)} */}
        </Flex>
      </BorderedCheckbox>
      {isDisabled && <LoadingSpinner size="md" />}
    </>
  );
};

export default FeaturedRecipesCheckbox;
