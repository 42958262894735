import {
  Badge,
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { Shimmer } from "react-shimmer";
import { RecipeVm } from "../../apiClient/clients";
import { brand500Color } from "../../pages/_app";
import getImageFromRecipe from "../../utils/getImageFromRecipe";

type Props = {
  recipe: RecipeVm;
  setSelectedRecipe: Dispatch<SetStateAction<RecipeVm | undefined>>;
  isSelected: boolean;
  hideCursor?: boolean;
};

const RecipeRow = ({
  recipe,
  setSelectedRecipe,
  isSelected,
  hideCursor = false,
}: Props) => {
  const border = isSelected ? `3px solid ${brand500Color}` : "transparent";

  return (
    <Flex
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      rounded="lg"
      shadow="md"
      alignItems="center"
      w="full"
      mb={4} // margin-bottom for spacing between rows
      cursor={hideCursor ? "default" : "pointer"}
      onClick={() => {
        setSelectedRecipe(recipe);
        console.log("");
        // console.log(recipe);
      }}
      border={border}
    >
      <Box flexShrink={0} mr={4}>
        <Image
          boxSize="85px"
          borderRadius="md"
          src={getImageFromRecipe({
            sizeOption: "500_500",
            recipeImage: recipe.recipeImages?.[0],
          })}
          alt={recipe.name}
          fallback={
            <Flex justify={"center"} height="100%" w="100%">
              <Shimmer height={200} width={200} className="shimmer-max-width" />
            </Flex>
          }
        />
      </Box>
      <Flex flexDirection="column" flexGrow={1}>
        <Text noOfLines={2} fontWeight="bold" mb={2}>
          {recipe.name}
        </Text>
        {recipe.isGloballyVisible && (
          <Badge w="70px" marginBottom={"1"} colorScheme="green">
            Featured
          </Badge>
        )}
        {/* <Flex>
          {recipe.prepTime?.hours ||
            recipe.prepTime?.minutes ||
            recipe.cookTime?.hours ||
            (recipe.cookTime?.minutes && (
              <>
                <Text mr={2}>{`${recipe.prepTime?.hours || "00"}:${
                  recipe.prepTime?.minutes || "00"
                }`}</Text>
                <Text mr={2}>{`${recipe.cookTime?.hours || "00"}:${
                  recipe.cookTime?.minutes || "00"
                }`}</Text>
              </>
            ))}
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default RecipeRow;
