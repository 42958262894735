import { useEffect } from "react";

type Props = {
  enabled: boolean;
  storageKey: string;
};

const useScrollToItem = ({ enabled = false, storageKey }: Props) => {
  useEffect(() => {
    try {
      if (enabled) {
        const id = localStorage.getItem(storageKey);
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            setTimeout(
              () =>
                element.scrollIntoView({
                  block: "center",
                  // inline: "center",
                  behavior: "smooth",
                  // alignToTop: false,
                }),

              0
            );
          }
          localStorage.removeItem(storageKey);
        }
      }
    } catch (error) {
      localStorage.removeItem(storageKey);
    }
  }, [enabled]);
};

export default useScrollToItem;
