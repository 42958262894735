import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";
//@ts-ignore

import { CreatableSelect } from "chakra-react-select";
import { useRef, useState } from "react";
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { $enum } from "ts-enum-util";
import { CreateRecipeCommand, RecipeVm } from "../../../apiClient/clients";
import { firebaseLogEvent } from "../../../config/firebase";
import { Measurement } from "../../../types";
import { AutoTextArea } from "../../Inputs";
import { NumberedListItem } from "../../NumberedListItem";
import RecipeIngredientQuantity from "./RecipeIngredientQuantity";

type Props = {
  isReadOnly?: boolean;
  recipe?: RecipeVm;
  customServings?: number;
};

export type OptionType = {
  value: string;
  label: string;
};

const measurementOptions: OptionType[] = [
  { value: "", label: "None" },
  ...$enum(Measurement)
    .getKeys()
    .map((m) => ({
      value: m,
      label: m,
    })),
];

const RecipeIngredientForm = ({
  isReadOnly,
  recipe,
  customServings,
}: Props) => {
  const {
    control,
    register,
    formState: { errors, defaultValues, dirtyFields },
    watch,
  } = useFormContext<CreateRecipeCommand>();

  const [isChecked, setIsChecked] = useState(false);

  const labelRef = useRef<HTMLLabelElement>(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ingredients",
  });

  const isIngredientLimitReached = fields?.length >= 40;

  if (isIngredientLimitReached) {
    firebaseLogEvent("ingredient_maximum_limit_reached");
  }

  // const parseMixedFraction = (quantityCombined: string) => {
  //   const parts = quantityCombined.split(" ");
  //   console.log(parts.length, "quantityCombined", quantityCombined);
  //   if (parts.length === 2) {
  //     const whole = parseInt(parts[0], 10);
  //     const [numerator, denominator] = parts[1].split("/").map(Number);
  //     return new Fraction(whole * denominator + numerator, denominator);
  //   } else {
  //     return new Fraction(quantityCombined);
  //   }
  // };

  const parseMixedFractionToDecimal = (quantityCombined: string) => {
    const parts = quantityCombined.split(" ");
    if (parts.length === 2) {
      const whole = parseInt(parts[0], 10);
      const [numerator, denominator] = parts[1].split("/").map(Number);
      const fraction = numerator / denominator || 0;
      return whole + fraction;
    }
    //Assuming they have just put in e.g. 1 as the whole number, and no fraction.
    if (!quantityCombined.includes("/")) {
      return parseFloat(quantityCombined);
    }

    // Assuming the input is just a fraction without a whole number
    const [numerator, denominator] = quantityCombined.split("/").map(Number);
    return numerator / denominator;
  };

  const scaleQuantity = (quantity: number, scalingFactor: number) => {
    return parseFloat((quantity * scalingFactor).toFixed(2)); // Adjust precision as needed
  };

  const getReadOnlyValue = (
    field: FieldArrayWithId<RecipeVm, "ingredients", "id">
  ) => {
    let combinedValue = "";

    const scalingFactor = customServings
      ? customServings / (recipe?.servings || 1)
      : undefined;

    if (field.quantityIsFraction && field.quantityFractionCombined) {
      // if (field.quantityNumerator) {
      //   combinedValue += field.quantityNumerator;
      // }

      // if (field.quantityDenominator) {
      //   if (combinedValue) {
      //     combinedValue += " " + field.quantityDenominator;
      //   } else {
      //     combinedValue += field.quantityDenominator;
      //   }
      // }
      let fractionToDecimal = parseMixedFractionToDecimal(
        field.quantityFractionCombined
      );

      //Only do it if they set a custom serving size
      if (scalingFactor && customServings !== recipe?.servings) {
        combinedValue += scaleQuantity(fractionToDecimal, scalingFactor);
      } else {
        combinedValue += field.quantityFractionCombined;
      }
    } else {
      if (field.quantity) {
        let value = scalingFactor
          ? field.quantity * scalingFactor
          : field.quantity;
        combinedValue += parseFloat(value.toFixed(2));
      }
    }

    if (field.measurement) {
      if (!combinedValue) {
        combinedValue += field.measurement;
      } else {
        combinedValue += " " + field.measurement;
      }
    }

    if (!combinedValue) {
      combinedValue += field.name;
    } else {
      combinedValue += " " + field.name;
    }

    return combinedValue;
  };

  return (
    <>
      <FormLabel>Ingredients</FormLabel>

      {fields.map((field, fieldIndex) => (
        <Flex
          key={`${field.id}-container`}
          flexDirection="column"
          height="100%"
        >
          {isReadOnly ? (
            <Flex gap="15px" alignItems={"center"}>
              <NumberedListItem text={fieldIndex + 1} />

              <AutoTextArea
                variant={"flushed"}
                isReadOnly={isReadOnly}
                value={getReadOnlyValue(field as RecipeVm)}
              />
            </Flex>
          ) : (
            <>
              <FormLabel
                key={`${field.id}-formlabel`}
                htmlFor={undefined}
                ref={labelRef}
              >
                Ingredient {fieldIndex + 1}:
              </FormLabel>
              <Flex key={field.id} gap="10px">
                <Flex gap="10px" w="100%" flexWrap={"wrap"}>
                  <Flex gap="10px" w="100%" wrap="wrap">
                    <RecipeIngredientQuantity
                      index={fieldIndex}
                      isReadOnly={isReadOnly}
                    />

                    <Flex w="100%">
                      {isReadOnly ? (
                        <Input
                          variant={"flushed"}
                          defaultValue={field.measurement}
                          placeholder="Measurement"
                          isReadOnly={isReadOnly}
                        />
                      ) : (
                        <Controller
                          control={control}
                          name={`ingredients.${fieldIndex}.measurement`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <div style={{ width: "100%" }}>
                              <CreatableSelect
                                isClearable
                                focusBorderColor="brand.500"
                                defaultInputValue={value || ""}
                                placeholder="Select or type a new measurement"
                                variant={"flushed"}
                                name={name}
                                ref={ref}
                                onChange={(value) => onChange(value?.value)}
                                onBlur={onBlur}
                                options={measurementOptions}
                                selectedOptionColorScheme="brand"
                              />
                            </div>
                          )}
                        />
                      )}
                    </Flex>
                  </Flex>

                  <AutoTextArea
                    {...register(`ingredients.${fieldIndex}.name`)}
                    placeholder="Ingredient"
                    variant={"flushed"}
                    isReadOnly={isReadOnly}
                    errorMessage={
                      errors.ingredients?.[fieldIndex]?.name?.message &&
                      errors.ingredients?.[fieldIndex]?.name?.message
                    }
                    autoFocus
                  />
                  <FormControl display="flex" alignItems="center">
                    <FormLabel
                      htmlFor={`ingredients.${fieldIndex}.quantityIsFraction`}
                      mb="0"
                    >
                      Fractions?
                    </FormLabel>
                    <Switch
                      autoFocus={false}
                      {...register(
                        `ingredients.${fieldIndex}.quantityIsFraction`
                      )}
                      // onChange={(e) => setIsChecked(e.target.checked)}
                    />
                  </FormControl>
                </Flex>

                <Flex
                  mt="10px"
                  alignItems={"center"}
                  _hover={{ cursor: "pointer" }}
                >
                  {!isReadOnly && (
                    <RiDeleteBin5Line
                      // leftIcon={<RiDeleteBin5Line color="red" size="24" />}
                      size="24"
                      color="red"
                      // variant={"link"}
                      onClick={() => remove(fieldIndex)}
                    />
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        // </FormControl>
      ))}

      {!isReadOnly && (
        <Flex
          justifyContent={"center"}
          mt="15px"
          alignItems={"center"}
          flexDir="column"
          gap="10px"
        >
          <Flex>
            <Button
              isDisabled={isIngredientLimitReached}
              id="AddIngredientBtn"
              onClick={(e) => {
                // e.preventDefault();
                // e.stopPropagation();
                const element = document.getElementById("AddIngredientBtn");
                if (element) {
                  setTimeout(
                    () =>
                      labelRef.current?.scrollIntoView({
                        block: "center",
                        behavior: "smooth",
                      }),
                    0
                  );
                }
                append({ name: "", isOptional: false });
              }}
              variant="outline"
              colorScheme="brand"
              leftIcon={<AiOutlinePlus />}
            >
              Add Ingredient
            </Button>
          </Flex>
          {isIngredientLimitReached && (
            <Text fontWeight={"semibold"} color="red">
              A maximum of 40 ingredients can be added
            </Text>
          )}
        </Flex>
      )}
    </>
  );
};

export default RecipeIngredientForm;
