export enum Measurement {
  Tsp,
  Tbps,
  Ml,
  L,
  Cup,
  G,
  Kg,
  Oz,
  Lb,
  Gal,
  Qt,
  Pt,
}

export type FileWithErrorMessage = {
  file?: File;
  preview?: string;
  errorMessage?: string;
  imageId?: string;
  storageUrl?: string;
};

export type ImageData = {
  base64: string;
  type: string;
};
