import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { FirebaseAuthProvider } from "../../apiClient/clients";
import {
  createNewUserAPI,
  firebaseLoginWithGoogle,
} from "../../config/firebase";
import appInsights from "../../utils/appInsights";
import logger from "../../utils/logger";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useSignInWithGoogle = ({ onSuccess, onError }: Props) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const { isLoading, mutate: signInWithGoogleMutate } = useMutation(
    async () => await firebaseLoginWithGoogle(),
    {
      onSuccess: async (user) => {
        queryClient.removeQueries();
        // queryClient.resetQueries();
        // queryClient.clear();

        try {
          appInsights.trackEvent({
            name: "UserAuthenticationEvent",
            properties: {
              message: "User Sign In with Google",
              type: "SignIn",
              method: "google",
              email: user?.email,
              uid: user?.uid,
            },
          });
        } catch (error) {}

        //Adding this here because, we're still randomly getting race conditions...

        await createNewUserAPI(user, FirebaseAuthProvider.Google);
        await router.push("/recipes");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        logger.error(err, "Error signing in with Google");
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, signInWithGoogleMutate };
};

export default useSignInWithGoogle;
