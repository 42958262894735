import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ImageData } from "../../types";

type Props = {
  onSuccess?: (text: string) => void;
  onError?: () => void;
};

const useGenerateRecipeChatGPTVision = ({ onSuccess, onError }: Props) => {
  const { mutate: uploadImages, isLoading } = useMutation(
    async (imagesData: ImageData[]) => {
      const url =
        process.env.NODE_ENV == "production"
          ? "https://recipelog-chatgpt-vision.azurewebsites.net/api/vision?code=434oReBbQf_hupS7jx9VfZxJlcIWdqOWtWk0eLW1u7iYAzFuwWDT5A=="
          : "http://localhost:7072/api/vision";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(imagesData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log(response);
      return response.json();
    },
    {
      onSuccess: (data) => {
        // Handle success
        toast.success(
          "Recipe extracted successfully! Generating recipe from text..."
        );
        console.log("Response data:", data);
        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        // Handle error
        toast.dismiss();
        toast.error("Failed to upload images.");
        console.error("There was a problem with the fetch operation:", error);
      },
    }
  );

  return { uploadImages, isLoading };
};

export default useGenerateRecipeChatGPTVision;
