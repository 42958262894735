import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { RecipeVm } from "../../apiClient/clients";
import { sessionStorageKeys } from "../../config";
import { firebaseLogEvent } from "../../config/firebase";
import appInsights from "../../utils/appInsights";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: (list: RecipeVm) => void;
  onError?: () => void;
};

const useToggleRecipeFavourite = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: toggleRecipeFavourite } = useMutation(
    async (recipeId: string) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("toggle_recipe_favourite", {
        recipe_id: recipeId,
      });

      return apiClient.toggleRecipeFavourite(recipeId, undefined);
    },
    {
      onSuccess: async (res) => {
        // await queryClient.invalidateQueries({ queryKey: [queryKeys.recipes] });

        try {
          const queryKeys = queryClient.getQueryCache().findAll();

          const recipeQueryKeys = queryKeys.filter(
            (key) =>
              Array.isArray(key.queryKey) && key.queryKey[0] === "recipes" // Adjust this as per your key structure
          );

          recipeQueryKeys.forEach((key) => {
            const oldData = queryClient.getQueryData<InfiniteData<RecipeVm[]>>(
              key.queryKey
            );

            if (oldData && oldData.pages) {
              // Create a new data structure by mapping over the pages
              const newData: InfiniteData<RecipeVm[]> = {
                ...oldData,
                pages: oldData.pages.map((page) =>
                  page.map((recipe) =>
                    recipe.id === res.id
                      ? { ...recipe, isFavourite: res.isFavourite }
                      : recipe
                  )
                ),
              };

              if (
                localStorage.getItem(sessionStorageKeys.recipesSortBy) ===
                "favourites"
              ) {
                // Sort each page individually
                newData.pages = newData.pages.map((page) =>
                  page.sort(
                    (a, b) => Number(b.isFavourite) - Number(a.isFavourite)
                  )
                );
              }

              // Update the query data with the new data
              queryClient.setQueryData(key.queryKey, newData);
            }
          });
        } catch (error) {
          console.error(error);
          await queryClient.invalidateQueries([queryKeys.recipes]);
        }

        // const queryKey = [queryKeys.recipes];
        // const recipes = queryClient.getQueryData<RecipeVm[]>(queryKey);

        // const recipe = recipes?.find((r) => r.id === res.id);
        // console.log(recipe);
        // if (recipe) {
        //   recipe.isFavourite = res.isFavourite;
        // }

        // queryClient.setQueryData(queryKey, recipes);

        const message = res.isFavourite
          ? `${res?.name} added to favourites`
          : `${res?.name} removed from favourites`;
        toast.success(message);
        onSuccess && onSuccess(res);

        // Update the data for each matching query
        // recipeQueryKeys.forEach((key) => {
        //   const oldData = queryClient.getQueryData<InfiniteData<RecipeVm[]>>(
        //     key.queryKey
        //   );
        //   const newData = oldData?.pages.map((r) =>
        //     r.id === res.id ? { ...r, ...{ isFavourite: res.isFavourite } } : r
        //   );
        //   queryClient.setQueryData(key.queryKey, newData);
        // });
      },
      onError: (err: any) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`There was an error favouriting this recipe - ${err}`);
        try {
          appInsights.trackException({ error: err });
        } catch (error) {}
      },
    }
  );

  return { isLoading, toggleRecipeFavourite };
};

export default useToggleRecipeFavourite;
