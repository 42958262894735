import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FirebaseError } from "firebase/app";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  firebaseLogEvent,
  firebaseLoginWithEmailAndPassword,
} from "../../config/firebase";
import appInsights from "../../utils/appInsights";
import logger from "../../utils/logger";
import handleError from "./handleErrorMessage";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useLogInWithEmailAndPassword = ({ onSuccess, onError }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const queryClient = useQueryClient();

  const router = useRouter();
  const {
    isLoading,
    mutate: logInWithEmailAndPasswordMutate,
    error,
  } = useMutation(
    async (credentials: { email: string; password: string }) => {
      await firebaseLoginWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
      await firebaseLogEvent("sign_in", { method: "email_password" });
      try {
        appInsights.trackEvent({
          name: "UserAuthenticationEvent",
          properties: {
            message: "User signed in with email and password",
            type: "Login",
            method: "email_password",
            email: credentials.email,
          },
        });
      } catch (error) {}
    },
    {
      onSuccess: async () => {
        queryClient.removeQueries();
        //  // queryClient.resetQueries();
        // queryClient.clear();

        await router.push("/recipes");
        onSuccess && onSuccess();
      },
      onError: (err: FirebaseError) => {
        logger.error(err, "Error logging in with email and password");
        console.log("Error.message", err.code);
        const errorMessage = handleError(err);
        setErrorMessage(errorMessage);
      },
    }
  );

  return { isLoading, logInWithEmailAndPasswordMutate, error, errorMessage };
};

export default useLogInWithEmailAndPassword;
