import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import apiClient from "../../apiClient/apiClient";
import { firebaseLogEvent } from "../../config/firebase";
import queryKeys from "../queryKeys";
import useAuthentication from "../useAuthentication";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

const useDeleteRecipeCollection = ({ onSuccess, onError }: Props) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuthentication();

  const { isLoading, mutate: deleteRecipeCollection } = useMutation(
    async (recipeCollectionId: string) => {
      await getAccessTokenSilently();

      await firebaseLogEvent("delete_recipe_collection", {
        recipe_collection_id: recipeCollectionId,
      });

      return apiClient.deleteRecipeCollection(recipeCollectionId, undefined);
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.recipeCollections],
        });
        toast.success("Recipe Collection has been deleted");
        onSuccess && onSuccess();
      },
      onError: (err) => {
        //TODO: Display validation errors from the BE if they exist.
        toast.error(`We ran into an error! Please try again - ${err}`);
      },
    }
  );

  return { isLoading, deleteRecipeCollection };
};

export default useDeleteRecipeCollection;
