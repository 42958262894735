import { Button, Flex, SimpleGrid } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingSpinner } from "../LoadingSpinner";

type Props = {
  hasNextPage?: boolean;
  fetchNextPage?: any;
  isFetchingNextPage?: boolean;
  dataLength?: number;
  displayStyle?: "grid" | "list";
};

const ReusableGrid = ({
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  dataLength,
  children,
  displayStyle = "grid",
}: Props & PropsWithChildren) => {
  const InfiniteScrollLoader = () => {
    if (isFetchingNextPage) {
      return (
        <Flex overflow="hidden">
          <h4>Loading more...</h4>
          <LoadingSpinner size="md" />
        </Flex>
      );
    }

    if (!!hasNextPage) {
      return (
        <Button colorScheme={"brand"} onClick={() => fetchNextPage()}>
          Load More
        </Button>
      );
    }

    return <></>;
  };

  return (
    <div
      id="scrollableDiv"
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      <InfiniteScroll
        scrollableTarget={"scrollableDiv"}
        dataLength={dataLength || 0}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<InfiniteScrollLoader />}
      >
        {displayStyle == "grid" ? (
          <SimpleGrid
            // id="test"
            // h="100%" //TODO:If grid breaks -  Added a max height.
            overflowX={"hidden"}
            minChildWidth={["150px", "200px", "250px"]}
            spacing={["15px", "30px"]}
            // h="100%"
            mt="5px"
            mr="20px"
            mb="5px"
          >
            {/* <Flex wrap={"wrap"} flexDir={"row"}> */}
            {children}
            {/* </Flex> */}
          </SimpleGrid>
        ) : (
          children
        )}
      </InfiniteScroll>
    </div>
  );
};

export default ReusableGrid;
